<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h1>You are logged out!</h1>
            <button class="btn btn-primary mt-4">
                <a class="nav-link text-white" routerLink="/"><i class="fas fa-sign-in-alt me-2"></i>Login</a>
            </button>
        </div>
    </div>
</div>


