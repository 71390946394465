import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Bank } from 'src/app/project-insights/models/invoice/bank.model';
import { InvoiceBasics } from 'src/app/project-insights/models/invoice/invoice-basics.model';
import { InvoiceModel } from 'src/app/project-insights/models/invoice/invoice.model';
import { BankService } from 'src/app/project-insights/services/bank.service';
import { CurrencyEnum } from 'src/app/shared/enums/currency.enum';

@Component({
  selector: 'app-edit-invoice-modal',
  templateUrl: './edit-invoice-modal.component.html',
  styleUrls: ['./edit-invoice-modal.component.scss']
})
export class EditInvoiceModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<InvoiceBasics>();

  invoice: InvoiceModel;
  banks: Bank[];
  CurrencyEnum = CurrencyEnum;

  form = new UntypedFormGroup({});
  showValidation = false;

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private bankService: BankService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.getBanks();
  }

  initFormGroup() {
    this.form = this.fb.group({
      companyName: [this.invoice.companyName, Validators.required],
      streetAndNumber: [this.invoice.streetAndNumber, Validators.required],
      postcodeAndCity: [this.invoice.postcodeAndCity, Validators.required],
      country: [this.invoice.country, Validators.required],
      customerOrderNumber: [this.initCustomerOrderNumber()],
      invoiceDate: [new Date(this.invoice.invoiceDate), Validators.required],
      paymentTermInDays: [this.invoice.paymentTermInDays, Validators.required],
      bankAccount: [this.invoice.bank.id, Validators.required],
      connectedWithWorktimes: [this.invoice.connectedWithWorktimes, Validators.required],
      startDate: [new Date(this.invoice.startDate), Validators.required],
      endDate: [new Date(this.invoice.endDate), Validators.required]
    });
  }

  getBanks() {
    this.bankService.getAllBanks()
      .subscribe((banks) => {
        this.banks = banks;
      });
  }

  onSubmit() {
    if (this.form.valid) {

      this.confirmed.emit(this.getInvoicePosition());
      this.close();
    }
    else {
      this.toastr.error('This form has validation errors!', '');
      this.form.markAllAsTouched();
    }
  }

  getInvoicePosition() {
    let updatedInvoice: InvoiceBasics = {
      companyName: this.form.controls['companyName'].value,
      streetAndNumber: this.form.controls['streetAndNumber'].value,
      postcodeAndCity: this.form.controls['postcodeAndCity'].value,
      country: this.form.controls['country'].value,
      customerOrderNumber: this.updateCustomerOrderNumber(),
      invoiceDate: this.form.controls['invoiceDate'].value,
      paymentTermInDays: this.form.controls['paymentTermInDays'].value,
      bankId: this.form.controls['bankAccount'].value,
      connectedWithWorktimes: this.form.controls['connectedWithWorktimes'].value,
      startDate: this.form.controls['startDate'].value,
      endDate: this.form.controls['endDate'].value,
    };
    return updatedInvoice;
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

  public close() {
    this.bsModalRef.hide();
  }
   
  initCustomerOrderNumber()
  {
    if (this.invoice.customerOrderNumber)
      return this.invoice.customerOrderNumber;
    else 
      return "";
  }

  updateCustomerOrderNumber()
  {
    if (this.form.controls['customerOrderNumber'].value === "")
      return null;
    else 
      return this.form.controls['customerOrderNumber'].value;
  }

}
