import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxEssentialsModule } from '@premotec/ngx-essentials';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AgGridFooterComponent } from './components/ag-grid-footer/ag-grid-footer.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DeleteConfirmationModalComponent } from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { CurrencyCustomPipe } from './pipes/currencyCustom.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DaterangepickerComponent } from './components/daterangepicker/daterangepicker.component';

const components = [
  LoadingModalComponent,
  AgGridFooterComponent,
  ConfirmationModalComponent,
  DeleteConfirmationModalComponent,
  DaterangepickerComponent
];

const pipes = [
  CurrencyCustomPipe
]
@NgModule({
  declarations: [
    components,
    pipes,
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    TranslocoModule,
    ReactiveFormsModule,
    NgxEssentialsModule,
    TooltipModule,
  ],
  exports: [
    components,
    pipes,
    TranslocoModule,
    NgxEssentialsModule,
    AgGridFooterComponent
  ], 
  providers: [
  ]
})
export class SharedModule { }
