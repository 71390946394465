import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillingGroupModel } from 'src/app/project-insights/models/billing-group/billing-group.model';
import { InvoicesByWorktimeModel } from 'src/app/project-insights/models/invoice/invoices-by-worktime.model';
import { BillingGroupService } from 'src/app/project-insights/services/billing-group.service';
import { ProjectService } from 'src/app/project-insights/services/project.service';

@Component({
  selector: 'app-create-invoice-modal',
  templateUrl: './create-invoice-modal.component.html',
  styleUrls: ['./create-invoice-modal.component.scss']
})
export class CreateInvoiceModalComponent extends BaseComponent implements OnInit {
  
  @Output() confirmed = new EventEmitter<InvoicesByWorktimeModel>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;

  //Modal variables

  title?: string;
  body?: string;
  okayButton?: string;
  cancelButton?: string;

  showMessageBox = false;
  message = new UntypedFormControl('', Validators.required);
  submitted = false;

  //Formular variable

  public selectedBillingGroupId: string;
  public billingGroups: BillingGroupModel[];
  public selectAll: boolean;
  public selectedBillingGroups: string[];

  public invoicesFromWorktime: InvoicesByWorktimeModel;

  public startDate: Date;
  public endDate: Date;

  public loading: boolean;

  public lastQuarter: number;
  public currentQuarter: number;
  public nextQuarter: number;

  public currentQuarterYear: number;
  public lastQuarterYear: number;
  public nextQuarterYear: number;

  public rangeWithIncompleteProjects: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private projectService: ProjectService,
    private billingGroupService: BillingGroupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initDefaultDates();
    this.setQuarters();
    this.loading = true;
    this.loadBillingGroups();
    this.selectAll = false;
  }

  //Modal functions

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  //Formular Functions

  initDefaultDates() {
    const dateNow = new Date();
    this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
    this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
  }

  createInvoicesFromWorktime() {
    this.invoicesFromWorktime = new InvoicesByWorktimeModel();
    this.invoicesFromWorktime.selectedBillingGroups = this.selectedBillingGroups;
    this.invoicesFromWorktime.startDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()));
    this.invoicesFromWorktime.endDate = this.endDate;

    this.confirmed.emit(this.invoicesFromWorktime);
    this.bsModalRef.hide();
  }

  checkIfProjectsAreIncomplete(){
    this.projectService.isAnyIncompleteProject(this.getShortDate(this.startDate), this.getShortDate(this.endDate)).subscribe((res)=>{
      if(res){
        this.rangeWithIncompleteProjects = true;
      } else {
        this.rangeWithIncompleteProjects = false;
      }
    })
  }

  loadBillingGroups() {
    this.billingGroupService.getActiveNotInternalBillingGroups().subscribe(x => {
      this.billingGroups = x;
      //this.selectedBillingGroupId = x[0].id;
      this.loading = false;
    });
  }

  selectedBillingGroupChanged(id: string) {
    this.selectedBillingGroupId = id;
  }

  setLastMonthDates() {
    this.initDefaultDates();
    this.checkIfProjectsAreIncomplete();
  }

  setCurrentMonthDates() {
    const dateNow = new Date();
    this.startDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.endDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
    this.checkIfProjectsAreIncomplete();
  }

  setCurrentQuarterDates() {
    const dateNow = new Date();
    this.currentQuarter = Math.floor(dateNow.getMonth() / 3);
    this.startDate = new Date(dateNow.getFullYear(), this.currentQuarter * 3, 1);
    this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 3, 0);
    this.checkIfProjectsAreIncomplete();
  }

  setLastQuarterDates() {
    const dateNow = new Date();
    const lastQuarterEndDate = this.getStartDate(dateNow);
    lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
    this.lastQuarter = this.getQuarter(lastQuarterEndDate);
    this.startDate = this.getStartDate(lastQuarterEndDate);
    this.endDate = lastQuarterEndDate;
    this.checkIfProjectsAreIncomplete();
  }

  setNextQuarterDates() {
    const dateNow = new Date();
    const nextQuarterStartDate = this.getEndDate(this.getStartDate(dateNow));
    nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);

    this.startDate = nextQuarterStartDate;
    this.endDate = this.getEndDate(this.startDate);
    this.checkIfProjectsAreIncomplete();
  }

  setQuarters() {

    const dateNow = new Date();

    this.currentQuarter = (this.getQuarter(dateNow));
    this.currentQuarterYear = dateNow.getFullYear();

    const lastQuarterEndDate = this.getStartDate(dateNow);
    lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
    this.lastQuarter = (this.getQuarter(lastQuarterEndDate));
    this.lastQuarterYear = lastQuarterEndDate.getFullYear();

    const nextQuarterStartDate = this.getEndDate(this.getStartDate(dateNow));
    nextQuarterStartDate.setDate(nextQuarterStartDate.getDate() + 1);
    this.nextQuarter = (this.getQuarter(nextQuarterStartDate));
    this.nextQuarterYear = nextQuarterStartDate.getFullYear();

  }

  getQuarter(date: Date) {
    return Math.floor(date.getMonth() / 3);
  }

  getStartDate(date: Date) {
    const quarter = this.getQuarter(date);
    return new Date(date.getFullYear(), quarter * 3, 1);
  }

  getEndDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 3, 0);
  }

	getShortDate(date: Date) {
		var dd = date.getDate();
		var mm = date.getMonth() + 1;
		var yyyy = date.getFullYear();
		return  yyyy + '-' + this.fillZero(mm) + '-' + this.fillZero(dd);
	}

  checkUncheckAll() {
    for (var i = 0; i < this.billingGroups.length; i++) {
      this.billingGroups[i].isSelected = this.selectAll;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.selectAll = this.billingGroups.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.selectedBillingGroups = [];
    for (var i = 0; i < this.billingGroups.length; i++) {
      if(this.billingGroups[i].isSelected)
      this.selectedBillingGroups.push(this.billingGroups[i].id);
    }
  }

	fillZero(number: number) {
		if (number < 10) {
			return '0' + number;
		}
		return number;
	}

  close(){
    this.bsModalRef.hide();
  }

}
