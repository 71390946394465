<div class="bg-dark custom-navbar">
      <div class="d-flex align-items-center mb-2">
        <div class="me-auto">
            <div class="text-white text-decoration-none">
                <a routerLink="">
                    <img src="assets/premotec_logo_horizontal.png" class="navbar-brand img-fluid" width="170">
                </a>
            </div>
        </div>

        <div class="d-flex align-items-center mb-1 me-4">
            <app-language-selection-menu></app-language-selection-menu>
        </div>

        <div class="d-flex align-items-center me-5">
            <div class="btn-group" dropdown>
                <a id="button-basic" dropdownToggle type="button" class="text-white dropdown-toggle" aria-controls="dropdown-basic">
                    <img src="https://png.pngtree.com/png-clipart/20190924/original/pngtree-vector-user-young-boy-avatar-icon-png-image_4827810.jpg" width="32" height="32" class="rounded-circle me-2">
                    <strong>{{ userInformation.firstName }}</strong> <span class="caret"></span>
                </a>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-dark" role="menu" aria-labelledby="button-basic">
                    <li>
                        <a class="dropdown-item cursor-pointer" routerLink="settings" routerLinkActive="active-link">
                            <i class="fa fa-cog"></i> <span> Settings</span>
                        </a>
                    </li>
                    <div class="dropdown-divider bg-secondary mx-2"></div>
                    <li> 
                        <a class="dropdown-item cursor-pointer" (click)="logOut()">
                            <i class="fa fa-sign-out-alt"></i><span> Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>