import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { registerEscClick } from 'ngx-bootstrap/utils';
import { IssueProjectGroup } from 'src/app/project-insights/models/issue/issue-project-groups-search.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';

@Component({
  selector: 'app-issue-project-groups-modal',
  templateUrl: './issue-project-groups-modal.component.html',
  styleUrls: ['./issue-project-groups-modal.component.scss']
})
export class IssueProjectGroupsModalComponent extends BaseComponent implements OnInit {
  
  @Output() confirmed = new EventEmitter<IssueProjectGroup[]>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;
  @Input() selectedProjectGroups: IssueProjectGroup[] = [];

  //Modal variables

  title?: string;
  body?: string;
  okayButton?: string;
  cancelButton?: string;

  showMessageBox = false;
  message = new FormControl('', Validators.required);
  submitted = false;

  public issueProjectGroups: IssueProjectGroup[] = [];
  public selectAll: boolean;

  public loading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public projectGroupService: ProjectGroupService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadProjectGroups();
    this.selectAll = false;
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }
  
  onSave() {
    this.confirmed.emit(this.selectedProjectGroups);
    this.bsModalRef.hide();
  }

  loadProjectGroups() {
    this.projectGroupService.getActiveProjectGroups().subscribe(x => {
      x.forEach(projectGroup => {
        const isSelected = this.selectedProjectGroups.some(selectedGroup => selectedGroup.id === projectGroup.id);
        let issueProjectGroup: IssueProjectGroup = {
          id: projectGroup.id,
          name: projectGroup.name,
          isActive: projectGroup.isActive,
          isSelected: isSelected
        };
        this.issueProjectGroups.push(issueProjectGroup);
      });
      this.selectAll = this.issueProjectGroups.every(group => group.isSelected);
      this.loading = false;
    });
  }
  
  checkUncheckAll() {
    for (var i = 0; i < this.issueProjectGroups.length; i++) {
      this.issueProjectGroups[i].isSelected = this.selectAll;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.selectAll = this.issueProjectGroups.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.selectedProjectGroups = [];
    for (var i = 0; i < this.issueProjectGroups.length; i++) {
      if(this.issueProjectGroups[i].isSelected)
      this.selectedProjectGroups.push(this.issueProjectGroups[i]);
    }
  }

  close(){
    this.bsModalRef.hide();
  }

}
