import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { AgGridModule } from 'ag-grid-angular';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NavbarModule } from './navigation/navbar.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProjectInsightsModule } from './project-insights/project-insights.module';
import { SecurityModule } from './security/security.module';
import { AuthInterceptor } from './security/services/auth.interceptor';
import {LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import {
  MsalGuard,
  MsalModule,
  MsalRedirectComponent
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


const defaultModules = [
  //BrowserModule,
  CommonModule,
  BrowserAnimationsModule,
  HttpClientModule,
];

const externalModules = [
  NgxDatepickerModule,
  TooltipModule.forRoot(),
  ToastrModule.forRoot(),
  TypeaheadModule.forRoot(),
  BsDropdownModule.forRoot(),
  TranslocoRootModule,
  AgGridModule,
  RouterModule,
  AppRoutingModule,
  SecurityModule,
  ModalModule.forRoot(),
  CommonModule,
  ReactiveFormsModule,
];

const internalModules = [
  AppRoutingModule,
  NavbarModule,
  SharedModule,
  ProjectInsightsModule,
];

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
};

registerLocaleData(localeDECH);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    defaultModules,
    externalModules,
    internalModules,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msal.clientId,
          authority: `https://login.microsoftonline.com/${environment.msal.tenantId}`,
          redirectUri: environment.msal.redirectUrl,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          allowRedirectInIframe: true,
          loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['openid'],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: undefined,
      }
    ),
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-CH' },
    MsalGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [HttpClientModule, BsDatepickerModule],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }