<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">

        <div class="card mb-3 sticky-toolbar">
            <div class="card-header">
                <div class="form-group">
                    <div class="btn-group me-2">
                        <button type="button" class="btn btn-primary me-2" (click)="createEstProjectCosts()">
                            <i class="fas fa-plus"></i>
                            Overview Project Cost Report
                        </button>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="d-flex pb-2">
            <h2 class="pe-3">Project Cost Reports</h2>
        </div>

        <ag-grid-angular 
            #agGrid
            class="ag-theme-alpine " 
            style="width: 100%; height: calc(100vh - 250px);"
            *ngIf="gridConfigReady" 
            [gridOptions]="gridOptions" 
            (gridReady)="onGridReady($event)"
            [rowData]="estProjectCosts" 
            [rowSelection]="'multiple'"
            [isRowSelectable]="isRowSelectable"
            (selectionChanged)="onSelectionChanged()"
            [defaultColDef]="defaultColDef"
            enableCellTextSelection="true"
            [tooltipShowDelay]="0"
            [enableBrowserTooltips]="true">
        </ag-grid-angular>

    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>