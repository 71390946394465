import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EstProjectCosts } from 'src/app/project-insights/models/est-project-costs/est-project-costs.model';
import { EstProjectCostsUpdate } from 'src/app/project-insights/models/est-project-costs/est-prpject-costs-update.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { EstProjectCostsService } from 'src/app/project-insights/services/est-project-costs.service';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';

@Component({
  selector: 'app-update-est-project-costs-modal',
  templateUrl: './update-est-project-costs-modal.component.html',
  styleUrls: ['./update-est-project-costs-modal.component.scss']
})
export class UpdateEstProjectCostsModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<EstProjectCostsUpdate>();
  @ViewChild('isActiveSwitch') isActiveSwitch: ElementRef;

  public form: FormGroup = new FormGroup(
    {
      projectGroupName: new FormControl('', [Validators.required]),
      isActive: new FormControl('',[Validators.required] ),
    },
  );

  estProjectCosts: EstProjectCosts;
  projectGroups: ProjectGroup[];
  currentProjectGroupName: string;
  public selectedProjectGroupId: string;


  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private estProjectCostsService: EstProjectCostsService,
    private projectGroupService: ProjectGroupService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.loadProjectGroups();
    this.currentProjectGroupName = this.estProjectCosts?.projectGroupName;
    this.selectedProjectGroupId = this.estProjectCosts.projectGroupId;
  }

  initFormGroup() {
    this.form = this.fb.group({
      projectGroupId:[this.estProjectCosts.projectGroupId,null],
      hourlyRateProject: [this.estProjectCosts?.hourlyRateProject, null],
      weightTasksInProgress: [this.estProjectCosts?.weightTasksInProgress, null],
      projectGroups: [this.projectGroups,null],
    })
  }


  onSubmit() {

    let estProjectCostsData: EstProjectCosts = {... this.form.value}
    estProjectCostsData.projectGroupId = this.selectedProjectGroupId;

    if (this.form.valid) {
      this.confirmed.emit(this.getProjectCostsReportDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }

  public close() {
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }

  loadProjectGroups() {
    this.projectGroupService.getProjectGroups().subscribe(x => {
      this.projectGroups = x;
    });
  }

  selectedProjectGroupChanged(id: string) {
    this.selectedProjectGroupId = id;
  }

  getProjectCostsReportDetails() {
    let projectCostsReportData: EstProjectCostsUpdate = {
      projectGroupId: this.selectedProjectGroupId,
      weightTasksInProgress: this.form.controls['weightTasksInProgress'].value,
      hourlyRateProject: this.form.controls['hourlyRateProject'].value,
    };
    return projectCostsReportData;
  }

}
