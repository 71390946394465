import {Component, OnDestroy} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Base component to use that helps handle cleaning up of ressources
 */

 @Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {

  /**
   * signals components death
   */
  protected destroyed$: Subject<any> = new Subject<any>();

  ngOnDestroy(): void {
    this.destroyed$.next(void 0); // signal 'death', so every component using subscriptions can clean up
    this.destroyed$.unsubscribe();
  }

  /**
   * Helper to stop subscriptions on components destruction
   * @param observable
   */
  protected whileImAlive<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
        takeUntil(this.destroyed$)
    );
  }
}
