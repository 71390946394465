import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ProjectGroupTimetrackerProjects } from 'src/app/project-insights/models/project-group/project-group-timetracker-projects.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { TimetrackerProject } from 'src/app/project-insights/models/timetracker-project/timetracker-project.model';

import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';
import { UpdateTimetrackerProjectsModalComponent } from './update-timetracker-projects-modal/update-timetracker-projects-modal.component';
//import { UpdateEmailSettingsModalComponent } from './update-email-settings-modal/update-email-settings-modal.component';

@Component({
  selector: 'app-timetracker-projects',
  templateUrl: './timetracker-projects.component.html',
  styleUrls: ['./timetracker-projects.component.scss']
})
export class TimetrackerProjectsComponent implements OnInit {

  @Input() projectGroup: ProjectGroup;

  public projects: TimetrackerProject[];

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private projectGroupService: ProjectGroupService) { }

  ngOnInit(): void {
    this.loadProjects();
  }

  loadProjects() {
    this.projectGroupService.getProjectGroupTimetrackerProjects(this.projectGroup.id).subscribe((x: any) => {
        this.projects = x;
    });
}


onEditTimetrackerProjects() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        projectGroup: this.projectGroup
      },
    };
    const bsModalRef = this.modalService.show(UpdateTimetrackerProjectsModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedTimetrackerProjects: ProjectGroupTimetrackerProjects) => {
        this.projectGroupService.updateProjectGroupTimetrackerProjects(this.projectGroup.id, updatedTimetrackerProjects).subscribe(
          x => {
            this.loadProjects();
            this.toastr.success("Data successfully updated!")
          },
          err => {
            this.toastr.error(err.error, "An error occured!")
          })
      })
  }

}
