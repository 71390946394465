import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DaterangepickerComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DaterangepickerComponent,
      multi: true,
    },
  ],
})
export class DaterangepickerComponent implements ControlValueAccessor, Validator {
  /** Minimum date which is enabled for selection */
  @Input() minDate: Date = new Date(1900, 0, 1);

  /** Maximum date which is enabled for selection */
  @Input() maxDate: Date = new Date(2100, 12, 31);

  /** Array of Dates, to declare only specific days that should be enabled to choose */
  @Input() datesEnabled: Date[] = [];

  /** Array of Dates, to declare only specific days that should be disabled to choose */
  @Input() datesDisabled: Date[] = [];

  /** The ID for references */
  @Input() id?: string;

  /** The tooltip text while hover on the icon */
  @Input() toolTipValue = 'Show Calender';

  /** The code for the icon from fontawesome */
  @Input() iconCode = 'fas fa-calendar';

  /** Define if the Datepicker should be able to show validations */
  @Input() showValidation: boolean | null = null;


  @Input() valueFormControl: FormControl = new FormControl();

  isDisabled = false;

  get isInvalidValue(): boolean {
    return (
      this.showValidation !== false &&
      this.elRef.nativeElement.classList.contains('is-invalid')
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange = (date: Date) => { };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => { };

  constructor(
    private localeService: BsLocaleService,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.localeService.use('de');
    this.valueFormControl.valueChanges.subscribe((date) => this.onChange(date));
  }

  ngAfterViewInit() {
    const config = { attributes: true, attributeFilter: ['class'] };
    const observer = new MutationObserver(() => {
      this.cdr.detectChanges();
    });
    observer.observe(this.elRef.nativeElement, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl): ValidationErrors | null {
    return this.valueFormControl.errors;
  }

  writeValue(obj: Date): void {
    this.valueFormControl.patchValue(obj, { emitEvent: false });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
