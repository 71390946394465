import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Issue } from '../models/issue/issue.model';
import { IssueSearchData } from '../models/issue/issue-search.model';
import { IssueOverview } from '../models/issue/issue-overview.model';


@Injectable({
    providedIn: 'root'
})
export class IssueService {

    constructor(private http: HttpClient) { }

    getIssuesBySearch(issueSearchData: IssueSearchData): Observable<IssueOverview> {
        return this.http.post<IssueOverview>(`${environment.api}/issue`, issueSearchData);
    }
}