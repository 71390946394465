<div class="btn-group" dropdown>
  <a id="button-basic" dropdownToggle type="button" class="text-white dropdown-toggle" aria-controls="dropdown-basic">
    <span class="fas fa-globe me-1"></span>
    {{activeLang | uppercase}}
  </a>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-dark" role="menu" aria-labelledby="button-basic">
    <li class="cursor-pointer" role="menuitem"><a class="dropdown-item" (click)="setActiveLang('de')">DE</a></li>
    <li class="cursor-pointer" role="menuitem"><a class="dropdown-item" (click)="setActiveLang('en')">EN</a></li>
  </ul>
</div>
