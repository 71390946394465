import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BillingGroupModel } from '../models/billing-group/billing-group.model';

@Injectable({
    providedIn: 'root'
})

export class BillingGroupService {

    constructor(private http: HttpClient) { }

    public getActiveNotInternalBillingGroups(): Observable<BillingGroupModel[]> {
        return this.http.get<BillingGroupModel[]>(`${environment.api}/billingGroup/activeNotInternal`);
    }
}