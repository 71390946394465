<div (ngSubmit)="onSubmit()">
    <div class="card mb-2">
        <div class="card-header border-bottom-0">
            <div class="row">

                <!-- Textfield / Buttons -->
                <div class="col-6 border-end">
                    <div class="mb-1">
                        <label id="textfield" class="justify-content-center">Text Search</label>
                        <div class="input-group">
                            <input for="textfield" type="text" class="form-control" [formControl]="textfieldControl" placeholder="GitLab project or Issue #">
                            <span class="input-group-text" tooltip="GitLab Issue Search">
                                <i class="fas fa-question"></i>
                            </span>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-3 d-flex justify-content-start">
                            <!-- Reset Search Button -->
                            <button type="button" class="btn btn-sm btn-secondary" (click)="resetSearchState()">
                                <i class="fas fa-clock-rotate-left"></i> Reset Search
                            </button>
                        </div>
                        <div class="col-5 d-flex justify-content-end">
                            <!-- Search in Description Toggle -->
                            <div class="form-check form-switch">
                                <input class="form-check-input small-toggle" type="checkbox" id="searchDescriptionSwitch" [formControl]="searchDescriptionControl">
                                <label class="form-check-label" for="searchDescriptionSwitch">Search in Description</label>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <!-- Load All Issues Toggle -->
                            <div class="form-check form-switch">
                                <input class="form-check-input small-toggle" type="checkbox" id="loadAllIssuesSwitch" [formControl]="loadAllIssuesControl">
                                <label class="form-check-label" for="loadAllIssuesSwitch">Load All Issues</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Daterange -->
                <div class="col-4 border-end">
                    <div class="row">
                        <div class="col-lg-9">
                            <label for="end" class="form-label">Date range</label>
                            <div class="mb-1">
                                <app-daterangepicker [formControl]="daterange"></app-daterangepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col buttons-h-list">
                            <button class="btn btn-light border me-2 btn-sm" (click)="setThisWeekDates()">This week</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentMonthDates()">Current month</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setLastMonthDates()">Last month</button>
                        </div>
                    </div>
                </div>

                <!-- Project Groups -->
                <div class="col-2">
                    <div>
                        <button type="button" (click)="selectProjectGroups()" class="btn btn-sm btn-light border">Choose Project Groups</button>
                        <hr class="my-1">
                        <ng-container *ngIf="selectedProjectGroups">
                            <span *ngFor="let projectGroup of selectedProjectGroups" class="badge bg-light border text-dark">{{projectGroup?.name}}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
