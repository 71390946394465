import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PmtUserRoleService {
  isLoggedIn: boolean = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) { }

  hasManagementRole(): Observable<boolean> {
    return this.authService.getUserRolesById().pipe(
        map(roles => roles.some(role => role.name === 'Management'))
    )}
}
