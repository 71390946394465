<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Create Project Cost Report</h4>
</div>
<div class="modal-body">
    <div class="projectGroupContainer">
        <label for="projectGroup">Select a project group</label>        
        <div class="row mt-3">
            <div class="switch-container btn-group me-2">
                <ul class="project-groups">
                    <li *ngFor="let projectGroup of projectGroups; let i = index">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="projectGroup" id="projectGroup" (change)="selectedProjectGroupChanged(projectGroup.id)">
                            <option value="{{projectGroup.id}}">
                                <label class="form-check-label" for="projectGroup">
                                    {{projectGroup.name}}
                                </label>
                            </option>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row mt-3">
            <label for="hourlyRateProject">Hourly rate project</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">CHF</span>
                <input type="number" class="form-control" id="hourlyRateProject" [(ngModel)]="hourlyRateProject" appTwoDecimalNumber>
            </div>
        </div>

        <div class="row mt-3">
            <label for="weightTasksInProgress">Weighting Tasks in progress</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">%</span>
                <input type="number" max="100" class="form-control" required id="weightTasksInProgress" [(ngModel)]="weightTasksInProgress" appTwoDecimalNumber>
            </div>
        </div>

    </div>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="decline()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success" (click)="createEstProjectCosts()"><i class="fas fa-save"></i> Save</button>
</div>