import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PlanningSearchData } from 'src/app/project-insights/models/planning/planning-searchdata.model';

@Component({
  selector: 'app-planning-searchbar',
  templateUrl: './planning-searchbar.component.html',
  styleUrls: ['./planning-searchbar.component.scss']
})

export class PlanningSearchbarComponent implements OnInit {

    @Output() planningSearchData = new EventEmitter<PlanningSearchData>();

    daterange: FormControl = new FormControl();

    dateRange: Date[] = [];

    constructor() {}

    ngOnInit(): void {
        this.setLastWeekDates();
    }

    resetSearchState() {
        this.onSubmit();
    }

    onSubmit() {
        const issueSearchData = this.getFormData();
        this.planningSearchData.emit(issueSearchData);
    }

    getFormData(): PlanningSearchData {
        let startDate = new Date(this.daterange.value[0]);
        let endDate = new Date(this.daterange.value[1]);
        
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        const planningSearchData: PlanningSearchData = {
            startDate: startDate,
            endDate: endDate
        };
        return planningSearchData;
    }

    setLastMonthDates() {
        const dateNow = new Date();
        this.dateRange = [];
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1, 0, 0, 0, 0));
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth(), 0, 23, 59, 59, 999));
        this.daterange.patchValue(this.dateRange);

        this.onSubmit();
    }

    setCurrentMonthDates() {
        const dateNow = new Date();
        this.dateRange = [];
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0, 0));
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0, 23, 59, 59, 999));
        this.daterange.patchValue(this.dateRange);
        
        this.onSubmit();
    }

    setThisWeekDates() {
        const dateNow = new Date();
        const currentDay = dateNow.getDay();
        
        let startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() + 1 - currentDay);
        startDate.setHours(0, 0, 0, 0);

        let endDate = new Date(dateNow);
        endDate.setDate(dateNow.getDate() + 7 - currentDay);
        endDate.setHours(23, 59, 59, 999);

        this.dateRange = [];
        this.dateRange.push(startDate);
        this.dateRange.push(endDate);
        this.daterange.patchValue(this.dateRange);

        this.onSubmit();
    }

    setLastWeekDates() {
        const dateNow = new Date();
        const dayOfWeek = dateNow.getDay();

        let startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() - dayOfWeek - 6);
        startDate.setHours(0, 0, 0, 0);

        let endDate = new Date(dateNow);
        endDate.setDate(dateNow.getDate() - dayOfWeek);
        endDate.setHours(23, 59, 59, 999);

        this.dateRange = [startDate, endDate];
        this.daterange.patchValue(this.dateRange);
        
        this.onSubmit();
    }

    setSecondToLastWeekDates() {
        const dateNow = new Date();
        const dayOfWeek = dateNow.getDay();

        let startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() - dayOfWeek - 13);
        startDate.setHours(0, 0, 0, 0);

        let endDate = new Date(dateNow);
        endDate.setDate(dateNow.getDate() - dayOfWeek - 7);
        endDate.setHours(23, 59, 59, 999);

        this.dateRange = [startDate, endDate];
        this.daterange.patchValue(this.dateRange);

        this.onSubmit();
    }
}
