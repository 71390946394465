import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseComponent {
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  title?: string;
  body?: string;
  icon?: string;
  color: string = 'primary';
  onlyConfirm: boolean;

  constructor(public bsModalRef: BsModalRef) {
    super();
  }

  confirm() {
    this.confirmed.emit(true);
    this.bsModalRef.hide();
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

}
