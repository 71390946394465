<ng-container *ngIf="userInformation">
  <app-top-navbar [userInformation]="userInformation"></app-top-navbar>
  <div class="main-wrapper">
    <div class="side-menu">
      <app-side-navbar (isCollapsedSidebar)="toggleCollapse(!$event)"></app-side-navbar>
    </div>
    <div class="main-content" [ngClass]="collapsed ? 'sidebar-collapsed-width' : 'sidebar-width'">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>