import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';

import { AgGridBaseComponent } from "src/app/shared/ag-grid-shared/ag-grid-base.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";

import { LoadingModalComponent } from "src/app/shared/components/loading-modal/loading-modal.component";
import { ProjectGroupService } from "src/app/project-insights/services/project-group.service";
import { GridService } from "src/app/shared/services/grid.service";
import { AgGridProjectGroupActionButtonsComponent } from "./ag-grid-project-group-action-buttons/ag-grid-project-group-action-buttons.component";
import { ProjectGroupGridColumnService } from "./project-group-grid-column.service";
import { ProjectGroup } from "src/app/project-insights/models/project-group/project-group.model";
import { ProjectGroupCreateModalComponent } from "../project-group-create/project-group-create.component";

@Component({
    templateUrl: './project-group-overview.component.html',
    styleUrls: ['./project-group-overview.component.scss'],
    providers: [GridService]
})
export class ProjectGroupOverviewComponent extends AgGridBaseComponent implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    modalRef?: BsModalRef;
    public projectGroups: ProjectGroup[];
    public loading: boolean;
    public importing: boolean;
    public showInvisibleProjectGroups: boolean;
    public dataTemplate: any;

    form: FormGroup;
    showInactive: boolean;
    activeSwitch = new FormControl()

    public defaultColDef: ColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true
    };

    constructor(
        private gridService: GridService,
        private projectGroupService: ProjectGroupService,
        private projectGroupGridColumnService: ProjectGroupGridColumnService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private toastr: ToastrService,
    ) {
        super();
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            switch: [null, Validators.requiredTrue]
        })
        this.updateData();
        this.initGrid();
        this.activeSwitch.valueChanges
            .subscribe((res) => {
                this.showActiveOrAll(res);
            })
    }

    private async initGrid() {
        this.addFrameWorkComponent('editButton', AgGridProjectGroupActionButtonsComponent);
        const columnDefs = await this.projectGroupGridColumnService.getColumns();
        this.gridOptions.columnDefs = columnDefs;
        this.gridOptions.suppressRowTransform = true;
        this.gridConfigReady = true;
    }

    public override onGridReady(parameters: GridReadyEvent): void {
        super.onGridReady(parameters);
        this.showActiveOrAll(false);
        this.autoSize();
        this.fillGrid()
    }

    updateData() {
        this.loading = true;
        this.projectGroupService.getProjectGroups().subscribe(
            x => {
                this.projectGroups = x;
                this.loading = false;
            });
    }

    openDetails(id: string) {
        this.router.navigateByUrl('/project-group/' + id);
    }

    toggleShowInvisibleProjectGroups() {
        this.showInvisibleProjectGroups = !this.showInvisibleProjectGroups;
    }

    showActiveOrAll(checked: boolean) {
        var isActiveFilterComponent = this.gridApi.getFilterInstance('isActive')!;
        if (checked) {
            isActiveFilterComponent.setModel({ type: 'equals', filter: '' });
        }
        else {
            isActiveFilterComponent.setModel({ type: 'equals', filter: 'true' });
        }
        this.gridApi.onFilterChanged();
    }

    autoSize() {
        const allColumnIds = [];
        this.gridOptions.columnApi.getAllGridColumns().forEach((column) => {
            if (!column['colDef'].width) {
                allColumnIds.push(column.getId());
            }
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
    }

    private fillGrid() {
        if (!this.projectGroups || this.projectGroups == null) { return; }

        this.gridService.processGrid(this.agGrid, this.projectGroups, this.constructor.name);
        this.agGrid.api.sizeColumnsToFit();
    }

    createProjectGroup() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                createOrUpdateMode: 'create'
            },
        };
        const bsModalRef = this.modalService.show(ProjectGroupCreateModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((projectGroupCreateModel) => {
                this.projectGroupService.createProjectGroup(projectGroupCreateModel)
                    .subscribe(x => {
                            this.toastr.success('Project group is successfully created!');
                            this.router.navigateByUrl(`/project-group/${x}`);
                    },
                        err => {
                            this.toastr.error('An error ocurred!')
                        }
                    )
            }
            )
    }

}