import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ProjectService {

    constructor(private http: HttpClient) { }

    // check if a list of projects(active) between two dates have at least a item incomplete
    public isAnyIncompleteProject(startDateAsString: string, endDateAsString: string): Observable<boolean> {
        return this.http.get<boolean>(`${environment.api}/project/haveIncompleteProjectsByDateRange?startDateAsString=${startDateAsString}&endDateAsString=${endDateAsString}`);
    }
}