import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EvaluationSearchDataModel } from '../models/evaluation/evaluation-searchdata.model';
import { EvaluationModel } from '../models/evaluation/evaluation.model';


@Injectable({
    providedIn: 'root'
})
export class EvaluationService {

    constructor(private http: HttpClient) { }

    getEvaluationBySearch(model: EvaluationSearchDataModel): Observable<EvaluationModel> {
        return this.http.post<EvaluationModel>(`${environment.api}/evaluation`, model);
    }
}