import { PlanningProjectGroupType } from "src/app/shared/enums/planning-project-group-type.enum";

export class PlanningActivityWorktime {
    employeeId: string;
    projectGroupId: string;
    billableHours: number;
    nonBillableHours: number;
}

export class PlanningActivity {
    name: string;
    type: PlanningProjectGroupType;
    worktimes: PlanningActivityWorktime[];
}