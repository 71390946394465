<div class="container-fluid mt-3">

    <div class="d-flex pb-2 ">
        <h2 class="pe-3">Billing: Summary</h2>
    </div>

    <app-billing-summary-searchbar (summarySearchData)="loadSummaryBySearch($event)"></app-billing-summary-searchbar>

    <ng-container *ngIf="loading">
        <div class="loading-container">
            <div class="loading-spinner">
                <div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="col-9" *ngIf="summaryItems">
        <div class="card mb-3">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h4>Grand total excl. VAT</h4>
                    <h4>{{grandTotal | currencyCustomPipe}}</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="col-9">
        <div *ngFor="let item of summaryItems">
            <div class="card mb-3">
                <div class="card-header">
                    <div class="d-flex justify-content-between">
                        <h4>{{item.billingGroupName}}</h4>
                        <button class="btn btn-light border me-2" type="button" (click)="getBillingOverviewPdf(item)">
                            Abrechnungsübersicht
                        </button>
                        <h4>{{item.totalPrice | currencyCustomPipe}}</h4>
                    </div>
                </div>
                <div class="card-body" *ngIf="item.positions && item.positions.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Project name</th>
                                <th>Billable</th>
                                <th>Non-Billable</th>
                                <th>Price</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let position of sortBy('sort', item.id); let i = index">
                                <td class="col-4">{{position.description}}</td>
                                <td class="col-2">{{position.amountBillable | currencyCustomPipe}}</td>
                                <td class="col-2">{{position.amountNonBillable | currencyCustomPipe}}</td>
                                <td class="col-2">{{position.price | currencyCustomPipe}}</td><!--currencyCustomPipe-->
                                <td class="col-2">{{(position.amountBillable + position.amountNonBillable) * position.price | currencyCustomPipe}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>