<div class="container-fluid mt-3">
    <div class="d-flex pb-2">
        <h2 class="pe-3">Evaluation</h2>
    </div>

    <app-evaluation-searchbar (evaluationSearchData)="loadEvaluationBySearch($event)"></app-evaluation-searchbar>

    <ng-container *ngIf="loading">
        <div class="loading-container">
            <div class="loading-spinner">
                <div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loading && evaluation">
        <div class="row">
            <div class="col-sm">
                <table class="table table-bordered table-striped">
                    <thead class="border-bottom">
                        <tr class="text-center">
                            <th style="width: 50%;"></th>
                            <th>Percentage</th>
                            <th>Hours</th>
                        </tr>
                    </thead>
                    <tbody class="border-bottom">
                        <tr>
                            <td class="text-left">Percentage of <b>Hours Worked</b> from Monthly Target</td>
                            <td class="text-center" style="color: red;">Pending update <a href="https://gitlab.premotec.ch/premotec/time-tracker/-/issues/48">(#48)</a></td>
                            <td class="text-center">{{ evaluation.total.hours }}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Percentage of <b>Sick Leave</b> from Total Hours</td>
                            <td class="text-center">{{ evaluation.sickLeave.percentage | number:'1.2-2' }} %</td>
                            <td class="text-center">{{ evaluation.sickLeave.hours}}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Percentage of <b>Absence</b> from Total Hours</td>
                            <td class="text-center">{{ evaluation.absence.percentage | number:'1.2-2' }} %</td>
                            <td class="text-center">{{ evaluation.absence.hours }}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Percentage of <b>Non-Billable</b> from Worked Hours (Excl. Absence)</td>
                            <td class="text-center">{{ evaluation.nonBillable.percentage | number:'1.2-2' }} %</td>
                            <td class="text-center">{{ evaluation.nonBillable.hours }}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Percentage of <b>Billable</b> from Worked Hours (Excl. Absence)</td>
                            <td class="text-center">{{ evaluation.billable.percentage | number:'1.2-2' }} %</td>
                            <td class="text-center">{{ evaluation.billable.hours }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>