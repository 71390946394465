<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">

        <div class="d-flex pb-2 ">
            <h2 class="pe-3">{{estProjectCosts.projectGroupName}} - Project Cost Report</h2>
        </div>

        <div>
            <p>
                <b>Created On: </b>
                {{estProjectCosts.createDate | date: 'dd. MMMM yyyy'}}
            </p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm">
                <table class="table">
                    <thead class="table-active">
                        <tr>
                            <th class="th-text">Project efforts</th>
                            <th class="th-number">Project effort in days</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Completed tasks</td>
                            <td class="td-number">
                                {{estProjectCosts.projectCostReportStats.completedTasksDays | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Open tasks</td>
                            <td class="td-number">
                                {{estProjectCosts.projectCostReportStats.openTasksDays | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Tasks in progress (weighted <strong>{{estProjectCosts.weightTasksInProgress}}%</strong>)&ensp;
                            <button type="button" class="btn btn-sm btn-primary" (click)="onEditEstProjectCosts()">
                                <i class="fas fa-edit" tooltip="{{ 'edit' | transloco}}"></i>
                            </button>
                            </td>
                            <td class="td-number">
                                {{estProjectCosts.projectCostReportStats.tasksInProgressDays | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <tr class="table-primary">
                            <td class="td-bold">Total amount of development effort</td>
                            <td class="td-number-bold">
                                {{estProjectCosts.projectCostReportStats.developmentEffortTotalAmount | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <hr>
                        <tr>
                            <td>Requirements engineering, specification, project management</td>
                            <td class="td-number">
                                {{estProjectCosts.projectCostReportStats.projectManagementDays | number : '0.2-2'}}
                            </td>
                            <td class="td-info-icon">
                                <button class="btn btn-info btn-sm me-2" tooltip="25% of Total amount of development effort"><i class="fas fa-question"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td>Documentation, testing and quality control</td>
                            <td class="td-number">
                                {{estProjectCosts.projectCostReportStats.documentationDays | number : '0.2-2'}}
                            </td>
                            <td class="td-info-icon">
                                <button class="btn btn-info btn-sm me-2" tooltip="12.5% of Total amount of development effort"><i class="fas fa-question"></i></button>
                            </td>
                        </tr>
                        <tr class="table-primary">
                            <td class="td-bold">Total amount of project management effort</td>
                            <td class="td-number-bold">
                                {{estProjectCosts.projectCostReportStats.projectManagementEffortTotalAmount | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <hr>
                        <tr class="table-primary">
                            <td class="td-bold">Total amount of estimate project effort</td>
                            <td class="td-number-bold">
                                {{estProjectCosts.projectCostReportStats.estimateProjectEffortTotalAmount | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Manual difference</td>
                            <td class="td-manual-difference">
                                <input type="number" step="0.01" class="form-control inline-input" (change)="manualDifferenceDaysChanged($event)" [value]="this.manualDifferenceDays | number : '0.2-2'">
                            </td> 
                            <td></td>
                        </tr>
                        <tr class="table-primary">
                            <td class="td-bold-large">Total</td>
                            <td class="td-number-bold">
                                {{estProjectCosts.projectCostReportStats.total | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>
                        <hr>
                        <tr class="table-active" style="border: black;">
                            <td class="td-bold">Project costs</td>
                            <td class="th-number-bold">CHF</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Hourly rate project
                            <button type="button" class="btn btn-sm btn-primary" (click)="onEditEstProjectCosts()">
                                <i class="fas fa-edit" tooltip="{{ 'edit' | transloco}}"></i>
                            </button>
                            </td>
                            <td class="td-number">
                                {{estProjectCosts.hourlyRateProject | number : '0.2-2'}}
                            </td>
                            <td></td>
                        </tr>                                                                                               
                        <tr>
                            <td>Project costs (at 8h per day) excl. MwSt</td>
                            <td class="td-number">
                                {{estProjectCosts.projectCosts | number : '0.2-2'}}
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>7.7 % MwSt</td>
                            <td class="td-number">
                                {{estProjectCosts.mwSt | number : '0.2-2'}}
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr class="table-primary">
                            <td class="td-bold">Total estimate project costs</td>
                            <td class="td-number-bold">
                                {{estProjectCosts.totalEstimateProjectCosts | number : '0.2-2'}}
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>