import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EstProjectCosts } from 'src/app/project-insights/models/est-project-costs/est-project-costs.model';
import { EstProjectCostsService } from 'src/app/project-insights/services/est-project-costs.service';
import { UpdateEstProjectCostsModalComponent } from '../../est-project-costs-detail/update-est-project-costs-modal/update-est-project-costs-modal.component';

@Component({
  selector: 'app-ag-grid-edit-button',
  templateUrl: './ag-grid-edit-button.component.html',
  styleUrls: []
})
export class AgGridEditButtonComponent implements ICellRendererAngularComp {

  bsModalRef?: BsModalRef;
  params: ICellRendererParams;
  value: string;
  gridApi: GridApi;
  gridData: any;
  estProjectCosts: EstProjectCosts;

  constructor(
    private router: Router,
    private estProjectCostsService: EstProjectCostsService,
    private modalService: BsModalService,
    ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.gridApi = params.api;
    
    this.estProjectCostsService.getEstProjectCostsById(this.params.data.id)
      .subscribe((x: EstProjectCosts) => {
        this.estProjectCosts = x;
      });
  }

  openDetails() {
    this.router.navigateByUrl('est-project-costs/' + this.params.data.id);
  }

  onEditEstProjectCosts() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        estProjectCosts: this.estProjectCosts
      },
    };
    const bsModalRef = this.modalService.show(UpdateEstProjectCostsModalComponent, initialState);

    /*bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedNameAndStatus: ProjectGroupNameStatus) => {
        this.projectGroupService.updateProjectGroupNameAndStatus(this.projectGroupId, updatedNameAndStatus)
          .subscribe(x => {
            this.projectGroup.name = updatedNameAndStatus.name
            this.projectGroup.isActive = updatedNameAndStatus.isActive
            this.toastr.success("Data successfully updated!")
          },
            err => {
              this.toastr.error(err.error, "An error occured!")
            })
      })*/

		/*const initialState: ModalOptions = {
			backdrop: 'static',
			class: 'modal-lg',
			keyboard: false,
			initialState: {
				employee: this.employee
			},
		};
		const bsModalRef = this.modalService.show(EditEmployeeDetailsModalComponent, initialState);

		bsModalRef.content?.confirmed.pipe(take(1))
			.subscribe(x => {
        this.loadProjects();
			})*/
	}

  loadEstProjectCosts() {
    
    this.estProjectCostsService.getEstProjectCostsById(this.params.data.id)
      .subscribe((x: EstProjectCosts) => {
        this.estProjectCosts = x;
      });
	}

  refresh() {
    return false;
  }
}
