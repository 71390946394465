<div class="card-footer py-1 px-3 border">
  <p class="m-0 p-0">
    <span class="spinner-border spinner-border-sm" role="status" *ngIf="isLoading()"></span>
    <span *ngIf="!isLoading()">
      <b>{{filteredRowCount}}</b> {{ 'row' | transloco }}
      <span *ngIf="filteredRowCount !== fullRowCount">({{ 'filteredRows' | transloco }}
        <b>{{fullRowCount}}</b>
      {{'rows' | transloco }})
      </span>
    </span>

    

    <button class="btn btn-sm btn-light" (click)="erase()" [disabled]="isLoading()">
      <i class="fas fa-eraser"></i>
      {{'resetFilter' | transloco }}
    </button>
  </p>
 
</div>

