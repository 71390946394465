<div class="container-fluid mt-3">

    <div class="d-flex pb-2 ">
        <h2 class="pe-3">Planning Overview</h2>
    </div>

    <app-planning-searchbar (planningSearchData)="loadPlanningBySearch($event)"></app-planning-searchbar>

    <ng-container *ngIf="loading">
        <div class="loading-container">
            <div class="loading-spinner">
                <div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loading">
        <div class="row">
            <div class="col-sm">
                <div class="alert alert-info">
                    <div [innerHTML]="'The first value indicated in the employee\'s time cell is the percentage of \<b>billable\</b> hours, the second - the percentage of \<b>non-billable\</b> hours.'"></div>
                </div>
                <table class="table table-bordered">
                    <thead class="border-bottom">
                        <tr class="text-center">
                          <th></th>
                          <th *ngFor="let employee of employees" >
                              {{ employee.firstName }}
                          </th>
                          <th>Total KW</th>
                        </tr>
                        <tr> 
                            <th class="text-left" style="white-space: nowrap;">Max. FTE per employee</th>
                            <td class="text-center" *ngFor="let employee of employees">
                                {{ employee.fte | number:'1.1-2' }}
                            </td>
                            <th class="text-center" style="min-width: 100px !important; text-align: center;">{{totalFte}}</th>
                        </tr>
                    </thead>
                    <tbody class="border-bottom">
                      <ng-container *ngFor="let project of projectGroups">
                        <tr>
                            <td>{{ project.name }}</td>
                            <td style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                                <ng-container *ngIf="getWorktime(employee.id, project.id) as worktime">
                                    {{ worktime.billableHours | number:'1.2-2' }} | {{ worktime.nonBillableHours | number:'1.2-2' }}
                                </ng-container>
                            </td>
                            <th style="min-width: 100px !important; text-align: center;">
                                <ng-container *ngIf="getTotalWorktimeForProject(project.id) as totalWorktime">
                                    {{ totalWorktime.totalBillableHours }} | {{ totalWorktime.totalNonBillableHours }}
                                </ng-container>
                            </th>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody class="border-bottom">
                        <ng-container *ngFor="let project of projectGroupsInternal">
                          <tr>
                              <td>{{ project.name }}</td>
                              <td style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                                  <ng-container *ngIf="getWorktime(employee.id, project.id) as worktime">
                                      {{ worktime.billableHours | number:'1.2-2' }} | {{ worktime.nonBillableHours | number:'1.2-2' }}
                                  </ng-container>
                              </td>
                              <th style="min-width: 100px !important; text-align: center;">
                                  <ng-container *ngIf="getTotalWorktimeForProject(project.id) as totalWorktime">
                                      {{ totalWorktime.totalBillableHours }} | {{ totalWorktime.totalNonBillableHours }}
                                  </ng-container>
                              </th>
                          </tr>
                        </ng-container>
                        <ng-container *ngFor="let activity of internalActivities">
                            <tr>
                                <td>{{ activity.name }}</td>
                                <td style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                                    <ng-container *ngIf="getActivityWorktime(employee.id, activity) as activityWorktime">
                                        {{ activityWorktime.billableHours | number:'1.2-2' }} | {{ activityWorktime.nonBillableHours | number:'1.2-2' }}
                                    </ng-container>
                                </td>
                                <th style="min-width: 100px !important; text-align: center;">
                                    <ng-container *ngIf="getTotalWorktimeForActivity(activity) as totalActivityWorktime">
                                        {{ totalActivityWorktime.totalBillableHours }} | {{ totalActivityWorktime.totalNonBillableHours }}
                                    </ng-container>
                                </th>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tbody class="border-bottom">
                        <ng-container *ngFor="let project of projectGroupsManagement">
                          <tr>
                              <td>{{ project.name }}</td>
                              <td style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                                  <ng-container *ngIf="getWorktime(employee.id, project.id) as worktime">
                                      {{ worktime.billableHours | number:'1.2-2' }} | {{ worktime.nonBillableHours | number:'1.2-2' }}
                                  </ng-container>
                              </td>
                              <th style="min-width: 100px !important; text-align: center;">
                                  <ng-container *ngIf="getTotalWorktimeForProject(project.id) as totalWorktime">
                                      {{ totalWorktime.totalBillableHours }} | {{ totalWorktime.totalNonBillableHours }}
                                  </ng-container>
                              </th>
                          </tr>
                        </ng-container>
                        <ng-container *ngFor="let activity of managementActivities">
                            <tr>
                                <td>{{ activity.name }}</td>
                                <td style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                                    <ng-container *ngIf="getActivityWorktime(employee.id, activity) as activityWorktime">
                                        {{ activityWorktime.billableHours | number:'1.2-2' }} | {{ activityWorktime.nonBillableHours | number:'1.2-2' }}
                                    </ng-container>
                                </td>
                                <th style="min-width: 100px !important; text-align: center;">
                                    <ng-container *ngIf="getTotalWorktimeForActivity(activity) as totalActivityWorktime">
                                        {{ totalActivityWorktime.totalBillableHours }} | {{ totalActivityWorktime.totalNonBillableHours }}
                                    </ng-container>
                                </th>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tbody class="border-bottom">
                        <ng-container *ngFor="let project of projectGroupsOverhead">
                          <tr class="bg-light">
                            <td>{{ project.name }}</td>
                            <td style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                              <ng-container *ngIf="getWorktime(employee.id, project.id) as worktime">
                                {{ worktime.billableHours | number:'1.2-2' }} | {{ worktime.nonBillableHours | number:'1.2-2' }}
                              </ng-container>
                            </td>
                            <th style="min-width: 100px !important; text-align: center;">
                              <ng-container *ngIf="getTotalWorktimeForProject(project.id) as totalWorktime">
                                {{ totalWorktime.totalBillableHours | number:'1.2-2' }} | {{ totalWorktime.totalNonBillableHours | number:'1.2-2' }}
                              </ng-container>
                            </th>
                          </tr>
                        </ng-container>
                    </tbody>
                    <tbody class="border-top">
                        <tr>
                            <th class="text-left" style="white-space: nowrap;">Total (excluding Away hours and Non-billable)</th>
                            <th style="min-width: 100px !important; text-align: center;" *ngFor="let employee of employees">
                                <ng-container *ngIf="calculateTotalWorktime(employee.id) as totalEmployeeHours">
                                    {{ totalEmployeeHours.totalBillableHours | number:'1.2-2' }} | {{ totalEmployeeHours.totalNonBillableHours | number:'1.2-2'}}
                                </ng-container>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>