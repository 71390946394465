import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { ToastrService } from 'ngx-toastr';
import { LastGitlabImportDates } from '../../models/import/LastGitlabImportDates.model';
import { LastTimeTrackerImportDates } from '../../models/import/lastTimeTrackerImportDates.model';
import { ImportService } from '../../services/import.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent extends BaseComponent implements OnInit {

  loading: boolean;
  importIsWorking: boolean;

  lastGitlabImportDate: LastGitlabImportDates;
  specifiedDate: Date;


  public lastTimeTrackerImportDates: LastTimeTrackerImportDates;
  startDate: Date;
  endDate: Date;

  constructor(
      private importService: ImportService,
      private toastrService: ToastrService) {
      super();
  }

  ngOnInit(): void {
    this.initDefaultDates();
    this.loading = true;
    this.getImportDates()
  }

  getImportDates() {
    this.loading = true;
    this.importService.getLastGitlabImportDate()
        .subscribe((lastGitlabImportDate: LastGitlabImportDates) => {
            this.lastGitlabImportDate = lastGitlabImportDate;
            this.loading = false;
      });
      this.importService.getLastTimetrackerImportDate()
      .subscribe((lastTimeTrackerImportDates: LastTimeTrackerImportDates) => {
        this.lastTimeTrackerImportDates = lastTimeTrackerImportDates;
        this.loading = false;
    });
  }

  import() {
    this.importIsWorking = true;
    this.startDate = this.specifiedDate;
    this.importService.triggerImport(this.startDate, this.endDate)
      .subscribe({
        next: () => {
          this.toastrService.success('Successfully imported!');
          this.importIsWorking = false;
          this.getImportDates();
        },
        error: (err) => {
          this.toastrService.error('An Error ocurred in the import!', err);
          this.importIsWorking = false;
        },
      });
  }

  initDefaultDates() {
    const dateNow = new Date();
    this.startDate = new Date(new Date().getFullYear(), new Date().getMonth()-2, new Date().getDate());
    this.endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1);
    this.specifiedDate = new Date(new Date().getFullYear(), new Date().getMonth() -2, new Date().getDate());
  }
}
