<div class="container-fluid mt-3" *ngIf="!loading">

    <!-- Toolbar -->
    <div class="card sticky-toolbar mb-4">
        <div class="card-header">
            <button class="btn btn-light border me-2" (click)="goBack()" delay="300" placement="right">
                <i class="fas fa-arrow-left"></i>
            </button>

            <button type="button" class="float-end btn btn-danger" (click)="onDeleteProjectGroup()">
                <i class="fas fa-trash"></i> Löschen
            </button>
        </div>
    </div>

    <!-- Title -->
    <header>
        <h1>Project Group - </h1>
        <h1>{{projectGroup?.name}}</h1>
        <span class="badge" [ngClass]="projectGroup.isActive ? 'bg-success' : 'bg-danger'">{{ projectGroup.isActive ? ('Active' | transloco) : ('Inactive' | transloco) }}</span>
        <button class="btn btn-primary btn-sm ms-3" tooltip="{{ 'edit' | transloco}}" (click)="onEditProjectGroupName()"><i class="fas fa-edit"></i></button>
    </header>

    <!-- Content -->
    <div class="row">
        <div class="col-6">
            <app-gitlab-projects [projectGroup]="projectGroup"></app-gitlab-projects>
        </div>
        <div class="col-6">
            <app-timetracker-projects [projectGroup]="projectGroup"></app-timetracker-projects>
        </div>
    </div>
</div>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>