import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LastGitlabImportDates } from '../models/import/LastGitlabImportDates.model';
import { LastTimeTrackerImportDates } from '../models/import/lastTimeTrackerImportDates.model';


@Injectable({
    providedIn: 'root'
})
export class ImportService {

    constructor(private http: HttpClient) { }

    public getLastGitlabImportDate(): Observable<LastGitlabImportDates> {
        return this.http.get<LastGitlabImportDates>(`${environment.api}/import/gitlab/lastImportDate`);
    }

    public getLastTimetrackerImportDate(): Observable<LastTimeTrackerImportDates> {
        return this.http.get<LastTimeTrackerImportDates>(`${environment.api}/import/timetracker/lastImportDates`);
    }

    public triggerImport(startDate: Date, endDate: Date): Observable<boolean> {
        return this.http.post<boolean>(`${environment.api}/import`, { startDate: startDate, endDate: endDate });
    }

}