<ng-container>
    <div class="container-fluid mt-3">
        <div class="d-flex pb-2 ">
            <h1 class="pe-3">Import</h1>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 border-end border-2">
                <h2><i class="fa-brands fa-gitlab"></i> Gitlab & <i class="fa-solid fa-stopwatch"></i> Timetracker</h2>
                <div class="alert alert-primary rounded-0 rounded-top" role="alert">
                    <div class="mb-2">
                        <span class="badge text-bg-secondary">1</span>
                        Import Issues, it's Milestones and Projects from Gitlab 
                        which has changed since the «Last Execution (Gitlab)».
                        <br>
                        <span class="badge text-bg-secondary">2</span> Remove Issues, which were removed in Gitlab from the import period start date until today.
                        <br>
                        <span class="badge text-bg-secondary">3</span> Import Customers, Projects and Worktimes from Timetracker, which has changed since the «Last Execution».
                        <br>
                        <span class="badge text-bg-secondary">4</span> Remove Worktimes, which were removed in the Timetracker from the import period start date until today.
                    </div>
                    <div class="row">
                        <div class="col-sm col-xl-3">
                            <b>Last Execution (Gitlab)</b><br>
                            <span class="badge rounded-pill text-bg-success">{{ lastGitlabImportDate?.lastExecutionDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
                        </div>
                        with
                        <div class="col-sm col-xl-3">
                            <b class="ms-1">Import period start date</b><br>
                            <span class="badge rounded-pill text-bg-primary">{{ lastGitlabImportDate?.specifiedDate | date: 'dd.MM.yyyy' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm col-xl-3">
                            <b>Last Execution (Timetracker)</b><br>
                            <span class="badge rounded-pill text-bg-success">{{ lastTimeTrackerImportDates?.lastExecutionDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
                        </div>
                        with
                        <div class="col-sm col-xl-3">
                            <b class="ms-1">Selected Daterange</b><br>
                            <span class="badge rounded-pill text-bg-primary">{{ lastTimeTrackerImportDates?.updatedWorktimesDaterangeStart | date: 'dd.MM.yyyy' }} - {{ lastTimeTrackerImportDates?.updatedWorktimesDaterangeEnd| date: 'dd.MM.yyyy' }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-4 col-xl-3">
                        <label for="startdate">Import period start date</label>
                        <pmt-datepicker
                            [(ngModel)]="specifiedDate"
                            id="startDate"
                            [toolTipValue]="'showCalender' | transloco"
                            autocomplete="off">
                        </pmt-datepicker>
                    </div>
                    <div class="col-sm-8 col-xl-9 d-flex align-items-end">
                        <button [disabled]="importIsWorking" type="button" class="btn btn-primary w-100" (click)="import()"><i class="fas fa-file-import"></i> Import from Gitlab and Timetracker</button>
                    </div>
                </div>
                <div *ngIf="importIsWorking" class="alert alert-warning rounded-0 rounded-top mt-3" role="alert">
                    <i class="fas fa-spinner fa-pulse fa-lg"></i>
                    Import process is working... please wait a few moments <br>
                </div>
            </div>  
        </div>
    </div>  
</ng-container>