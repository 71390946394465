import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CreateBillingOverviewModel } from 'src/app/project-insights/models/billing-summary/create-billing-overview.model';
import { SummaryPosition } from 'src/app/project-insights/models/billing-summary/summary-position.model';
import { SummarySearchData } from 'src/app/project-insights/models/billing-summary/summary-searchdata.model';
import { SummaryStatistics } from 'src/app/project-insights/models/billing-summary/summary-statistics.model';
import { BillingSummaryService } from 'src/app/project-insights/services/billing-summary.service';
import { LoadingModalComponent } from 'src/app/shared/components/loading-modal/loading-modal.component';

@Component({
    selector: 'app-billing-summary',
    templateUrl: './billing-summary.component.html',
    styleUrls: ['./billing-summary.component.scss']
  })
export class BillingSummaryComponent extends BaseComponent implements OnInit {
  
    loading: boolean;

    public summaryItems: SummaryStatistics[];
    public downloadPdfModal?: BsModalRef | null;
	public grandTotal: number = 0;
    private searchData: SummarySearchData;
  
    constructor(
        private billingSummaryService: BillingSummaryService,
        private toastr: ToastrService,
        private modalService: BsModalService,
    ) {
        super();
    }
  
    ngOnInit(): void {
    }

    sortBy(prop: string, id: string) {
        let item = this.summaryItems.find(i => i.id === id);
        return item.positions.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    sortPositions(id: string){
        let item = this.summaryItems.find(i => i.id === id);

        let positions: SummaryPosition[] = [];
        let areSummaryPositionsSorted = false;
        //check if position is already sorted
        if (item.positions.length > 1 && !(item.positions[0].sort === 0 && item.positions[1].sort === 0)) {
            areSummaryPositionsSorted = true
        }
        for (let i = 0; i < item.positions.length; i++) {
            if (!areSummaryPositionsSorted) {
                item.positions[i].sort = i
            }
            positions.push(item.positions[i]);
        }
        item.positions = positions;
    }

    loadSummaryBySearch(summarySearchData: SummarySearchData) {
        this.loading = true;

        if (summarySearchData != null) {
            this.searchData = summarySearchData;
        }

        this.grandTotal = 0;

        if(summarySearchData != null){
            this.billingSummaryService.getSummaryBySearch(summarySearchData).subscribe(res => {
                this.summaryItems = res.sort((a, b) => a.billingGroupName.localeCompare(b.billingGroupName));

                this.summaryItems.forEach(item => {
                    this.sortPositions(item.id);
                    this.calculatePricesForSummary(item.id);
                })

                this.loading = false;
            },
            (err) => {
                this.toastr.error('An error occurred while loading billing summary!')
                this.loading = false;
            })
        }
	}

	calculatePricesForSummary(id: string) {
        let item = this.summaryItems.find(i => i.id === id);
        if (item) {
            item.totalPrice = 0;
            item.totalVat = 0;
            item.totalPriceVat = 0;
    
            item.positions.forEach(x => {
                item.totalPrice += (x.amountNonBillable + x.amountBillable) * x.price;
                item.totalVat += ((x.amountNonBillable + x.amountBillable) * x.price) / 100 * x.vatPct
            });
            item.totalPriceVat = item.totalPrice + item.totalVat;
			
			this.grandTotal += item.totalPrice;
        }
    }

    getBillingOverviewPdf(billingGroup: SummaryStatistics) {

        const config = {
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              title: 'Generating Billing Overview PDF'
            },
        }

        let model: CreateBillingOverviewModel = {
            startDate: this.searchData.startDate, 
            endDate: this.searchData.endDate,
            billingGroupId: billingGroup.id
        }

        this.downloadPdfModal = this.modalService.show(LoadingModalComponent, config);

        this.billingSummaryService.getBillingOverviewPdf(model).subscribe(fileBytes => {
            var fileName = `Billing Overview - ${billingGroup.billingGroupName}.pdf`;
            const data = `data:application/pdf;base64,${fileBytes}`;
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            this.toastr.success('Billing Overview PDF successfully created');
        },
            error => {
                this.toastr.error(error.error)
            }).add(()=>{
                this.downloadPdfModal.hide();
            })
    }
}