<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Basic Data</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form" autocomplete="off">
        <div class="row mb-2">
            <div class="col-12">
                <label for="projectGroupNameModal" class="form-label">{{'ProjectGroupName'}}*</label>
                <input id="projectGroupNameModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('projectGroupName')" formControlName="projectGroupName">
                    <div class="invalid-feedback">{{ 'validationMsg.required'}}</div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <label for="isActiveModal" class="form-label">{{'Status'}}</label>
                <div class="form-check form-switch">
                    <input id="isActiveModal" class="form-check-input" type="checkbox" role="switch" formControlName="isActive" [ngClass]="validateField('isActive')" #isActiveSwitch>
                    <label for="isActiveModal" class="form-label">
                        <span *ngIf="tempActiveState" class="badge bg-success">{{'Active'}}</span>
                        <span *ngIf="!tempActiveState" class="badge bg-danger">{{'Inactive'}}</span>
                    </label>
                    <div class="invalid-feedback">{{ 'validationMsg.required'}}</div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <div class="form-check form-switch">
                    <input id="isInternalModal" class="form-check-input" type="checkbox" role="switch" formControlName="isInternal" [ngClass]="validateField('isInternal')" #isInternalSwitch>
                    <label for="isInternalModal" class="form-label">Internal</label>
                    <div class="invalid-feedback">{{ 'validationMsg.required'}}</div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>