import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ProjectGroup } from "src/app/project-insights/models/project-group/project-group.model";
import { ProjectStatus } from "src/app/project-insights/models/project-status/project-status.model";
import { ProjectGroupService } from "src/app/project-insights/services/project-group.service";
import { ProjectStatusService } from "src/app/project-insights/services/project-status.service";

@Component({
    selector: 'app-project-status-overview',
    templateUrl: './project-status-overview.component.html',
    styleUrls: ['./project-status-overview.component.scss']
  })

export class ProjectStatusOverviewComponent implements OnInit {

    public projectGroups: ProjectGroup[];
    public loading: boolean;
    
    public selectedProjectGroupId: string;
    public projectStatus: ProjectStatus;


    constructor(
        private fb: FormBuilder,
        private httpClient: HttpClient,
        private toastr: ToastrService,
        private projectGroupService: ProjectGroupService,
        private projectStatusService: ProjectStatusService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.loadProjectGroups();
    }

    loadProjectGroups() {
        this.projectGroupService.getActiveProjectGroups().subscribe(x => {
        this.projectGroups = x;
        this.selectedProjectGroupId = null;
        });
    }

    selectedProjectGroupChanged(id: string) {
        this.selectedProjectGroupId = id;
        this.loadProjectStatus();
    }

    loadProjectStatus(){
        this.projectStatusService.getProjectStatusByProjectGroupId(this.selectedProjectGroupId)
        .subscribe(x => {
            this.projectStatus = x;
        });
    }

    chunkArray(array: any[], chunkSize: number): any[][] {
        const result: any[][] = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
        }
        return result;
      }
}