import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-language-selection-menu',
  templateUrl: './language-selection-menu.component.html',
  styleUrls: ['./language-selection-menu.component.scss'],
})
export class LanguageSelectionMenuComponent {

  get activeLang(): string {
    return this.translocoService.getActiveLang();
  }

  constructor(private translocoService: TranslocoService) {
  }

  setActiveLang(langCode: string) {
    this.translocoService.setActiveLang(langCode);
  }

}
