import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GitlabProject } from 'src/app/project-insights/models/gitlab-project/gitlab-project.model';
import { ProjectGroupGitlabProjects } from 'src/app/project-insights/models/project-group/project-group-gitlab-projects.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';

@Component({
  selector: 'app-update-gitlab-projects-modal',
  templateUrl: './update-gitlab-projects-modal.component.html',
  styleUrls: ['./update-gitlab-projects-modal.component.scss']
})
export class UpdateGitlabProjectsModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<ProjectGroupGitlabProjects>();

  form: FormGroup;

  projectGroup: ProjectGroup;
  projectGroupId: boolean;
  gitlabProject: GitlabProject;
  searchText: '';


  message = new FormControl('', Validators.required);
  submitted = false;
  gitlabProjects: GitlabProject[];

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private projectGroupService: ProjectGroupService) { }


  ngOnInit(): void {
    this.loadGitlabProjects();
    this.initFormGroup();
  }

  loadGitlabProjects() {
    this.projectGroupService.getGitlabProjects().subscribe(x => {
      this.gitlabProjects = x;

      this.gitlabProjects.forEach(element => {
        this.projectGroupId = null;
        if(element.projectGroupId === this.projectGroup.id)
            {
                this.projectGroupId = true;
                this.initGitlabProjectsForm(element.id, element.name, this.projectGroupId);
            }
        if(element.projectGroupId === null) 
        {
            this.projectGroupId = false;
            this.initGitlabProjectsForm(element.id, element.name, this.projectGroupId);
        }
      });
    });
  }


  initFormGroup() {
    this.form = this.fb.group({
      id: [this.projectGroup?.id, Validators.required],
      gitlabProjectsArray: this.fb.array([])
    });
  }

  initGitlabProjectsForm(id:string, projectName: string, isActive: boolean)
  {
    const gitlabProjectForm = this.fb.group({
      id:[id],
      projectName: [projectName, Validators.required],
      isActive: [isActive, [Validators.required]],
    });

    this.gitlabProjectsArray.push(gitlabProjectForm);
  }

  get gitlabProjectsArray(): FormArray {
    return this.form.controls['gitlabProjectsArray'] as FormArray;
  }

   onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getGitlabProjectsDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }
  
  getGitlabProjectsDetails() {
    let gitlabProjectsArrayData = this.form.get('gitlabProjectsArray').value;
    let projectsGitlab : GitlabProject[] = [];

    gitlabProjectsArrayData.forEach(element => {
        const gitlabProject: GitlabProject = {
            id: element.id,
            name: element.projectName,
            projectGroupId: element.isActive === true ? this.projectGroup.id : null
        };
        projectsGitlab.push(gitlabProject)
    });
    
    let updatedGitlabProjects: ProjectGroupGitlabProjects = {
        projects: projectsGitlab
      };

    return updatedGitlabProjects;
  }

  public close() {
    this.bsModalRef.hide();
  }


  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }
} 
