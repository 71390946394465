<div (ngSubmit)="onSubmit()">
    <div class="card mb-2">
        <div class="card-header border-bottom-0">
            <div class="row">

                <!-- Daterange -->
                <div class="col-6">
                    <div class="row">
                        <div class="col-lg-9">
                            <label for="end" class="form-label">Date range</label>
                            <div class="mb-1">
                                <app-daterangepicker [formControl]="daterange" (ngModelChange)="onSubmit()"></app-daterangepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col buttons-h-list">
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setSecondToLastWeekDates()">Second to last week</button>
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setLastWeekDates()">Last week</button>
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setThisWeekDates()">This week</button>
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setLastMonthDates()">Last month</button>
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setCurrentMonthDates()">This month</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
