import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectGroupTimetrackerProjects } from 'src/app/project-insights/models/project-group/project-group-timetracker-projects.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { TimetrackerProject } from 'src/app/project-insights/models/timetracker-project/timetracker-project.model';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';

@Component({
  selector: 'app-update-timetracker-projects-modal',
  templateUrl: './update-timetracker-projects-modal.component.html',
  styleUrls: ['./update-timetracker-projects-modal.component.scss']
})
export class UpdateTimetrackerProjectsModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<ProjectGroupTimetrackerProjects>();

  form: FormGroup;

  projectGroup: ProjectGroup;
  projectGroupId: boolean;
  timetrackerProject: TimetrackerProject;

  message = new FormControl('', Validators.required);
  submitted = false;
  timetrackerProjects: TimetrackerProject[];
  searchText: '';

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private projectGroupService: ProjectGroupService) { }


  ngOnInit(): void {
    this.loadTimeTrackerProjects();
    this.initFormGroup();
  }

  loadTimeTrackerProjects() {
    this.projectGroupService.getTimetrackerProjects().subscribe(x => {
      this.timetrackerProjects = x;
      this.timetrackerProjects.forEach(element => {
        this.projectGroupId = null;
        if(element.projectGroupId === this.projectGroup.id)
            {
                this.projectGroupId = true;
                this.initProjectEmployeeForm(element.id, element.name, this.projectGroupId, element.customerName);
            }
        if(element.projectGroupId === null) 
        {
            this.projectGroupId = false;
            this.initProjectEmployeeForm(element.id, element.name, this.projectGroupId, element.customerName);
        }
      });
    });
  }


  initFormGroup() {
    this.form = this.fb.group({
      id: [this.projectGroup?.id, Validators.required],
      timetrackerProjectsArray: this.fb.array([])
    });
  }

  initProjectEmployeeForm(id:string, projectName: string, isActive: boolean, customerName: string)
  {
    const timetrackerProjectForm = this.fb.group({
      id:[id],
      projectName: [projectName, Validators.required],
      isActive: [isActive, [Validators.required]],
      customerName: [customerName, Validators.required],
    });

    this.timetrackerProjectsArray.push(timetrackerProjectForm);
  }

  get timetrackerProjectsArray(): FormArray {
    return this.form.controls['timetrackerProjectsArray'] as FormArray;
  }

   onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getTimetrackerProjectsDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }

  getTimetrackerProjectsDetails() {
    let timetrackerProjectsArrayData = this.form.get('timetrackerProjectsArray').value;
    let projectsTT : TimetrackerProject[] = [];

    timetrackerProjectsArrayData.forEach(element => {
        const timetrackerProject: TimetrackerProject = {
            id: element.id,
            name: element.projectName,
            projectGroupId: element.isActive === true ? this.projectGroup.id : null,
            customerName: ''
        };
        projectsTT.push(timetrackerProject)
    });
    
    let updatedTimetrackerProjects: ProjectGroupTimetrackerProjects = {
        projects: projectsTT
      };

    return updatedTimetrackerProjects;
  }

  public close() {
    this.bsModalRef.hide();
  }


  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }
} 
