import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PlanningSearchData } from '../models/planning/planning-searchdata.model';
import { PlanningOverview } from '../models/planning/planning-overview.model';


@Injectable({
    providedIn: 'root'
})
export class PlanningService {

    constructor(private http: HttpClient) { }

    getPlanningWorktimesBySearch(planningSearchData: PlanningSearchData): Observable<PlanningOverview> {
        return this.http.post<PlanningOverview>(`${environment.api}/planning`, planningSearchData);
    }
}