<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">

        <div class="d-flex pb-2">
            <h2 class="pe-3">Project Status</h2>
        </div> 

        <div class="row">
            <div class="form-group">
                <label for="projectGroup">Project group:</label> 
                <div class="switch-container btn-group me-2">
                    <div class="form-check">
                        <select name="projectGroup" id="projectGroup" class="form-select" (change)="selectedProjectGroupChanged($event.target.value)">
                            <option [ngValue]="null" selected disabled></option>
                            <option *ngFor="let projectGroup of projectGroups;" value="{{projectGroup.id}}">{{projectGroup.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    <ng-container *ngIf="selectedProjectGroupId !== null">
        <div class="row">
            <div class="col-sm">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="first-column-est"> <i class="fa-regular fa-rectangle-list"></i> Open ({{projectStatus.issuesTotals.totalOpen}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-list-check"></i> ToDo ({{projectStatus.issuesTotals.totalToDo}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-forward"></i> New Version ({{projectStatus.issuesTotals.totalNextVersion}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-spinner"></i> Doing ({{projectStatus.issuesTotals.totalDoing}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-rotate-right"></i> Re-Open ToDo ({{projectStatus.issuesTotals.totalReOpenToDo}})</th>
                            <th class="first-column-est"> <i class="fa-regular fa-circle-check"></i> Resolved ({{projectStatus.issuesTotals.totalResolved}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-flask-vial"></i> Test-Ready ({{projectStatus.issuesTotals.totalTestReady}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-flask-vial"></i> Testing by PMT ({{projectStatus.issuesTotals.totalTestingByPMT}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-vial-virus"></i> Testing by Client ({{projectStatus.issuesTotals.totalTestingByClient}})</th>
                            <th class="first-column-est"> <i class="fa-solid fa-check"></i> Done ({{projectStatus.issuesTotals.totalDone}})</th>
                            <th class="first-column-est"> Grand total ({{projectStatus.issuesTotals.grandTotal}})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="first-column">Estimate total</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalOpen}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalToDo}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalNextVersion}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalDoing}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalReOpenToDo}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalResolved}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalTestReady}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalTestingByPMT}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalTestingByClient}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.totalDone}}</td>
                            <td class="first-column-est">{{projectStatus.estimates.grandTotal}}</td>
                        </tr>
                        <tr *ngFor="let projectStats of projectStatus.projectStats">
                            <td class="first-column">{{projectStats[0].epicTitle}}</td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[0].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[0].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[0].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[0].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[0].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[1].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[1].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[1].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[1].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[1].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[2].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[2].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[2].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[2].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[2].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[3].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[3].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[3].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[3].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[3].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[4].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[4].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[4].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[4].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[4].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[5].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[5].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[5].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[5].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[5].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[6].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[6].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[6].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[6].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[6].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[7].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[7].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[7].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[7].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[7].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[8].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[8].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[8].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[8].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[8].estimateTotal}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="stats">
                                    <p>Issues:</p>
                                    <p class="stats-right">{{projectStats[9].issues}}</p>
                                </div>
                                <div class="stats">
                                    <p>Billable total:</p>
                                    <p class="stats-right">{{projectStats[9].billableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Non billable total:</p>
                                    <p class="stats-right">{{projectStats[9].nonBillableTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Grand total:</p>
                                    <p class="stats-right">{{projectStats[9].grandTotal}}</p>
                                </div>
                                <div class="stats">
                                    <p>Estimate total:</p>
                                    <p class="stats-right">{{projectStats[9].estimateTotal}}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>

        <div class="card-deck mb-3">
            <div class="row" *ngFor="let row of chunkArray(projectStatus.closedMilestonesStats, 4)">
              <div class="col-md-3" *ngFor="let milestoneStats of row">
                <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-title">{{ milestoneStats.epicTitle }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="stats">
                            <p>Issues:</p>
                            <p class="stats-right">{{ milestoneStats.issues }}</p>
                        </div>
                        <div class="stats">
                            <p>Billable total:</p>
                            <p class="stats-right">{{ milestoneStats.billableTotal }}</p>
                        </div>
                        <div class="stats">
                            <p>Non billable total:</p>
                            <p class="stats-right">{{ milestoneStats.nonBillableTotal }}</p>
                        </div>
                        <div class="stats">
                            <p>Grand total:</p>
                            <p class="stats-right">{{ milestoneStats.grandTotal }}</p>
                        </div>
                        <div class="stats">
                            <p>Estimate total:</p>
                            <p class="stats-right">{{ milestoneStats.estimateTotal }}</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </ng-container>

    </div>
</ng-container>