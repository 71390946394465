import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@premotec/ngx-essentials/lib/components/base/base.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Issue } from '../../../models/issue/issue.model';
import { IssueService } from '../../../services/issue.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GitlabProject } from 'src/app/project-insights/models/gitlab-project/gitlab-project.model';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';
import { IssueSearchData } from 'src/app/project-insights/models/issue/issue-search.model';
import { TranslocoService } from '@ngneat/transloco';
import { IssueOverview } from 'src/app/project-insights/models/issue/issue-overview.model';
import { IssueCalculatedInfo } from 'src/app/project-insights/models/issue/issue-calculated-info.model';

@Component({
	selector: 'app-issue-overview',
	templateUrl: './issue-overview.component.html',
	styleUrls: ['./issue-overview.component.scss']
})
export class IssueOverviewComponent implements OnInit {

	public loading: boolean;
	public onInit: boolean = true;

	public issues: Issue[];
	public issueOverview: IssueOverview;
	public issueCalculatedInfo: IssueCalculatedInfo;
	public timetrackerProject: string;
	public issueNumber: number;
	public gitlabProjects: GitlabProject[];
	public gitlabProjectId: string;
	
	constructor(
		private issueService: IssueService,
		private projectGroupService: ProjectGroupService,
		private toastr: ToastrService,
		private translateService: TranslocoService,
		private cd: ChangeDetectorRef,
		private modalService: BsModalService,
		private router: Router,
	) {}

	ngOnInit(): void {
	}

	loadIssuesBySearch(issueSearchData: IssueSearchData) {

		let searchData: IssueSearchData;
		
		if (searchData != null) {
			issueSearchData = searchData;
		}

		if((issueSearchData.textfield === null && this.onInit == false) || issueSearchData != null){
			this.onInit = false;
			this.startLoading();
			this.issueService.getIssuesBySearch(issueSearchData)
			.subscribe({
				next: (x: IssueOverview) => {
					this.issueOverview = x;
					this.issues = x.issueModels;
					this.issues.forEach(element => {
						if(element.milestones.length > 1 ){
							element.milestoneString = element.milestones[0] + " (" + element.milestones[1] + ")";
						}
						else{
							element.milestoneString = element.milestones[0];
						}
					});
				},
				error: () => this.toastr.error(this.translateService.translate('toastr.error.loading'))
			})
			.add(() => this.loading = false);

		}
	  }
	
	
	startLoading() {
		this.loading = true;
		this.cd.detectChanges(); // This will prevent an error, which is caused from change detection
	}

	loadGitlabProjects() {
		this.projectGroupService.getGitlabProjects().subscribe(x => {
			this.gitlabProjects = x;
		})
	}
}
