import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SummarySearchData } from '../models/billing-summary/summary-searchdata.model';
import { SummaryStatistics } from '../models/billing-summary/summary-statistics.model';
import { CreateBillingOverviewModel } from '../models/billing-summary/create-billing-overview.model';

@Injectable({
    providedIn: 'root'
})
export class BillingSummaryService {

    constructor(private http: HttpClient) { }

    getSummaryBySearch(summarySearchData: SummarySearchData): Observable<SummaryStatistics[]> {
        return this.http.post<SummaryStatistics[]>(`${environment.api}/billingSummary`, summarySearchData);
    }

    getBillingOverviewPdf(model: CreateBillingOverviewModel): Observable<string> {
        return this.http.post<string>(`${environment.api}/billingSummary/pdf`, model);
    }
}