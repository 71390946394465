import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ProjectGroupNameStatus } from 'src/app/project-insights/models/project-group/project-group-name-status.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';
import { DeleteConfirmationModalComponent } from 'src/app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { UpdateNameAndStatusModalComponent } from './update-name-and-status-modal/update-name-and-status-modal.component';

@Component({
  selector: 'app-project-group-details',
  templateUrl: './project-group-details.component.html',
  styleUrls: ['./project-group-details.component.scss']
})
export class ProjectGroupDetailsComponent implements OnInit {

  loading: boolean;
  projectGroupId: string;
  projectGroup: ProjectGroup;

  constructor(
    public location: Location,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private projectGroupService: ProjectGroupService,) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe(params => {
      if (params['id']) { this.projectGroupId = params['id']; }
    });
    this.loadProjectGroup();
  }

  loadProjectGroup() {
    this.projectGroupService.getProjectGroupById(this.projectGroupId)
      .subscribe((x: ProjectGroup) => {
        this.projectGroup = x;
        this.loading = false;
      });
  }

  onEditProjectGroupName() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        projectGroup: this.projectGroup
      },
    };
    const bsModalRef = this.modalService.show(UpdateNameAndStatusModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedNameAndStatus: ProjectGroupNameStatus) => {
        this.projectGroupService.updateProjectGroupNameAndStatus(this.projectGroupId, updatedNameAndStatus)
          .subscribe(x => {
            this.projectGroup.name = updatedNameAndStatus.name
            this.projectGroup.isActive = updatedNameAndStatus.isActive
            this.toastr.success("Data successfully updated!")
          },
            err => {
              this.toastr.error(err.error, "An error occured!")
            })
      })
  }

  goBack()
  {
    this.router.navigateByUrl(`/project-group`);
  }

  onDeleteProjectGroup() {
    const initialState: ModalOptions = {
      initialState: {
        body: `Do you really want to delete the Project group - <b>${this.projectGroup?.name}</b> ?`,
        okayButton: 'OK',
        cancelButton: 'Cancel',
      }
    };
    const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe(() => {
        this.projectGroupService.deleteProjectGroup(this.projectGroupId)
          .subscribe(
            res => {
              this.toastr.success('Project group successfully removed!')
              this.router.navigateByUrl('/project-group');
            },
            err => { this.toastr.error(`An error occured during remove project group. ${err.error}`) }
          );
      });
  }
}
