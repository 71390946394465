<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Create Project Group</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form">
        <div class="row">
            <div class="mb-2 col-12">
                <label class="form-label" for="name">Project Group Name*</label>
                <input type="text" class="form-control" id="name"
                    [ngClass]="validateField('name')"
                    formControlName="name">
                <div class="invalid-feedback">This field is required.</div>
            </div>
            
            <div class="mb-2 col-4">
                <label for="isActiveModal" class="form-label">Status</label>
                <div class="form-check form-switch">
                    <input id="isActiveModal" class="form-check-input" type="checkbox" role="switch" formControlName="isActive">
                    <label for="isActiveModal" class="form-label">
                        <span *ngIf="tempActiveState" class="badge bg-success">Active</span>
                        <span *ngIf="!tempActiveState" class="badge bg-danger">Inactive</span>
                    </label>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <div class="form-check form-switch">
                        <input id="isInternalModal" class="form-check-input" type="checkbox" role="switch" formControlName="isInternal" [ngClass]="validateField('isInternal')" #isInternalSwitch>
                        <label for="isInternalModal" class="form-label">Internal</label>
                        <div class="invalid-feedback">{{ 'validationMsg.required'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>