import { Injectable } from '@angular/core';
import { AutoFocusFixDirective } from '@premotec/ngx-essentials';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridProjectGroupActionButtonsComponent } from './ag-grid-project-group-action-buttons/ag-grid-project-group-action-buttons.component';

@Injectable()
export class ProjectGroupGridColumnService {
  constructor() { }
  async getColumns(): Promise<(ColDef | ColGroupDef)[]> {

    const cols: ColDef[] = [
        {
          field: 'name',
          headerName: 'Project group name',
          minWidth: 480
        },
        {
            field: 'isActive',
            headerName: 'Is Active',
            filter: true,            
            minWidth: 120
        },
      {
        field: 'editButton',
        headerName: '',
        filter: false,
        sortable: false,
        cellRenderer: AgGridProjectGroupActionButtonsComponent,
        cellEditorPopupPosition: 'under',
        tooltipField: "edit",
        minWidth: 75, 
        maxWidth: 75, 
        pinned: 'right'
      },
    ]

    return cols
  }

}