export const environment = {
    production: false,
    api: 'https://api.pip-test.premotec.ch',
    msal: {
      scopeUri: ['api://96178809-8e4f-4b13-b00c-065b865cd820/project-insights'],
      uiClienId: '47e069b4-5eae-4b11-b0aa-ce348843da7e',
      tenantId: '1cf9f76d-0bf6-45b1-b4bd-ecfe2ff24050',
      clientId: 'fdc59110-b39b-4c62-bb4b-efbd6a78320b',
      redirectUrl: 'https://pip-test.premotec.ch'
    },
    hangfire: 'https://api.pip-test.premotec.ch/hangfire'

};