import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SummarySearchData } from 'src/app/project-insights/models/billing-summary/summary-searchdata.model';
import { PlanningSearchData } from 'src/app/project-insights/models/planning/planning-searchdata.model';

@Component({
  selector: 'app-billing-summary-searchbar',
  templateUrl: './billing-summary-searchbar.component.html',
  styleUrls: ['./billing-summary-searchbar.component.scss']
})

export class BillingSummarySearchbarComponent implements OnInit {

    @Output() summarySearchData = new EventEmitter<SummarySearchData>();

    daterange: FormControl = new FormControl();
    dateRange: Date[] = [];

    startDate: Date;
    endDate: Date;

    currentQuarter: number;
    currentQuarterYear: number;
    lastQuarter: number;
    lastQuarterYear: number;
    nextQuarter: number;
    nextQuarterYear: number;

    constructor() {}

    ngOnInit(): void {
        this.setQuarters();
    }

    resetSearchState() {
        this.dateRange = [];
        this.daterange.reset();
    }

    onSubmit() {
        const issueSearchData = this.getFormData();
        this.summarySearchData.emit(issueSearchData);
    }

    getFormData(): PlanningSearchData {
        let startDate = new Date(this.daterange.value[0]);
        let endDate = new Date(this.daterange.value[1]);
        
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        const summarySearchData: SummarySearchData = {
            startDate: startDate,
            endDate: endDate
        };
        
        return summarySearchData;
    }

    setLastMonthDates() {
        const dateNow = new Date();
        this.dateRange = [];
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1, 0, 0, 0, 0));
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth(), 0, 23, 59, 59, 999));
        this.daterange.patchValue(this.dateRange);

        this.onSubmit();
    }

    setCurrentMonthDates() {
        const dateNow = new Date();
        this.dateRange = [];
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0, 0));
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0, 23, 59, 59, 999));
        this.daterange.patchValue(this.dateRange);
        
        this.onSubmit();
    }

    setCurrentQuarterDates(): void {
        this.setQuarterDates(new Date(), 0);
        this.onSubmit();
    }
    
    setLastQuarterDates(): void {
        const now = new Date();
        this.setQuarterDates(now, -1);
        this.onSubmit();
    }
    
    setNextQuarterDates(): void {
        const now = new Date();
        this.setQuarterDates(now, 1);
        this.onSubmit();
    }
    
    setQuarterDates(date: Date, offset: number): void {
        const quarter = Math.floor((date.getMonth() / 3) + offset) % 4;
        const year = date.getFullYear() + Math.floor((date.getMonth() / 3 + offset) / 4);
        this.startDate = new Date(year, quarter * 3, 1);
        this.endDate = new Date(year, quarter * 3 + 3, 0);
        this.dateRange = [this.startDate, this.endDate];
        this.daterange.patchValue(this.dateRange);
    }
    
    setQuarters(): void {
        const now = new Date();
        this.currentQuarter = this.getQuarter(now);
        this.currentQuarterYear = now.getFullYear();
    
        const lastQuarterEndDate = new Date(now);
        lastQuarterEndDate.setMonth(now.getMonth() - 3);
        this.lastQuarter = this.getQuarter(lastQuarterEndDate);
        this.lastQuarterYear = lastQuarterEndDate.getFullYear();
    
        const nextQuarterStartDate = new Date(now);
        nextQuarterStartDate.setMonth(now.getMonth() + 3);
        this.nextQuarter = this.getQuarter(nextQuarterStartDate);
        this.nextQuarterYear = nextQuarterStartDate.getFullYear();
    }
    
    getQuarter(date: Date): number {
        return Math.floor(date.getMonth() / 3);
    }
    
    setCurrentWeekDates(): void {
        this.setWeekDates(0);
        this.onSubmit();
    }
    
    setLastWeekDates(): void {
        this.setWeekDates(-1);
        this.onSubmit();
    }
    
    setWeekBeforeLastDates(): void {
        this.setWeekDates(-2);
        this.onSubmit();
    }
    
    setWeekDates(weekOffset: number): void {
        const today = new Date();
        const start = this.startOfWeek(this.addDays(today, weekOffset * 7), 1);
        this.startDate = start;
        this.endDate = this.addDays(start, 6);
        this.dateRange = [this.startDate, this.endDate];
        this.daterange.patchValue(this.dateRange);
    }
    
    startOfWeek(date: Date, weekStartsOn: number): Date {
        const diff = (date.getDay() < weekStartsOn ? 7 : 0) + date.getDay() - weekStartsOn;
        const start = new Date(date);
        start.setDate(date.getDate() - diff);
        start.setHours(0, 0, 0, 0);
        return start;
    }
    
    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(date.getDate() + days);
        return result;
    }
}
