import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TranslocoService } from '@ngneat/transloco';
import { take } from "rxjs/operators";
import { BaseComponent } from "src/lib/base.component";
import { DeleteConfirmationModalComponent } from "src/app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component";
import { LoadingModalComponent } from "src/app/shared/components/loading-modal/loading-modal.component";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
import { EstProjectCosts } from "src/app/project-insights/models/est-project-costs/est-project-costs.model";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UpdateEstProjectCostsModalComponent } from "./update-est-project-costs-modal/update-est-project-costs-modal.component";
import { EstProjectCostsService } from "src/app/project-insights/services/est-project-costs.service";
import { EstProjectCostsUpdate } from "src/app/project-insights/models/est-project-costs/est-prpject-costs-update.model";

@Component({
    templateUrl: './est-project-costs-details.component.html',
    styleUrls: ['./est-project-costs-details.component.scss']
})
export class EstProjectCostsDetailsComponent extends BaseComponent implements OnInit {

    public estProjectCosts: EstProjectCosts;
    
    public estProjectCostsId: string;
    public loading: boolean; //= true;
    public invoiceDate: Date;
    public connectedWithWorktimes: boolean;
    public invoiceIsEditable: boolean;
    public activityJournalIsVisible: boolean;
    public sendEmailText: string;
    public isEmailSent: boolean;
    public sendEmailButtonVisability: boolean;
    public projectGroupName: string;
    public salutationFromBillingGroup: string;
    public manualDifferenceDays: number;


    formSubmitting: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe,
        private modalService: BsModalService,
        private transloco: TranslocoService,
        private toastr: ToastrService,
        private estProjectCostsService: EstProjectCostsService,
    ) {
        super();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.estProjectCostsId = params['id'];
            }
        });
    }

    ngOnInit(): void {
        this.loading = true;
        this.loadEstProjectCosts();

    }

    loadEstProjectCosts() {
        this.estProjectCostsService.getEstProjectCostsById(this.estProjectCostsId)
        .subscribe((x: EstProjectCosts) => {
            this.estProjectCosts = x;
            this.manualDifferenceDays = this.estProjectCosts.projectCostReportStats.manualDifferenceDays;
            this.loading = false;
    });
    }


    manualDifferenceDaysChanged(event: any) { 
        this.manualDifferenceDays = event.target.value;

        const initialState: ModalOptions = {
            initialState: {
              body: 'Do you really want to change Manual difference? <br> New Project Cost Report will be created!',
              cancelButton: "Cancel"
            },
          };
          const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);
      
          bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
              this.estProjectCosts.projectCostReportStats.manualDifferenceDays = this.manualDifferenceDays;
              this.estProjectCostsService.createEstProjectCosts(this.estProjectCosts)
                .subscribe((x) => {
                    this.toastr.success('Project Cost Report is successfully created!'); 
                    
                    this.router.navigateByUrl(`/est-project-costs/${x}`);
                    this.estProjectCostsService.getEstProjectCostsById(x)
                        .subscribe((y: EstProjectCosts) => {
                            this.estProjectCosts = y;
                            this.manualDifferenceDays = this.estProjectCosts.projectCostReportStats.manualDifferenceDays;
                            this.loading = false;
                    });
                },
                
                    err => {
                        this.toastr.error('An error ocurred!')
                    }
                )
            })

            bsModalRef.content?.declined.pipe(take(1))
            .subscribe(() => {
                this.manualDifferenceDays = this.estProjectCosts.projectCostReportStats.manualDifferenceDays;
                }
            )
      }

      onEditEstProjectCosts() {
        const initialState: ModalOptions = {
          backdrop: 'static',
          keyboard: false,
          initialState: {
            estProjectCosts: this.estProjectCosts
          },
        };
        const bsModalRef = this.modalService.show(UpdateEstProjectCostsModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
        .subscribe((updatedGitlabProjects: EstProjectCostsUpdate) => {
        this.estProjectCostsService.updateEstProjectCosts(this.estProjectCosts.id, updatedGitlabProjects).subscribe(
            x => {

            this.toastr.success("Project Cost Report is successfully updated!");
            this.router.navigateByUrl(`/est-project-costs/${x}`);
                    this.estProjectCostsService.getEstProjectCostsById(x)
                        .subscribe((y: EstProjectCosts) => {
                            this.estProjectCosts = y;
                            this.manualDifferenceDays = this.estProjectCosts.projectCostReportStats.manualDifferenceDays;
                            this.loading = false;
                    })
            },
            err => {
            this.toastr.error(err.error, "An error occured!")
            })
        })
    }
}