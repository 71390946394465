import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Employee } from '../models/employee/employee.model';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(private http: HttpClient) { }

    getAllEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(`${environment.api}/employee/all`);
    }

    getEduEmployees(): Observable<Employee[]> {
        return this.http.get<Employee[]>(`${environment.api}/employee/team/edu`);
    }
}