<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Update overview Project Cost Report</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form" autocomplete="off">
        <label for="projectGroup">Select a project group</label>        
        <div class="row mt-3">
            <div class="switch-container btn-group me-2">
                <ul class="project-groups">
                    <li *ngFor="let projectGroup of projectGroups; let i = index">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="projectGroup" id="projectGroup" [checked]="(this.projectGroup.name == this.currentProjectGroupName)" (change)="selectedProjectGroupChanged(projectGroup.id)">
                            <option value="{{projectGroup.id}}">
                                <label class="form-check-label" for="projectGroup">
                                    {{projectGroup.name}}
                                </label>
                            </option>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-3">
            <label for="hourlyRateProject">Hourly rate project</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">CHF</span>
                <input type="number" class="form-control" id="hourlyRateProject" formControlName="hourlyRateProject" appTwoDecimalNumber>
            </div>
        </div>
        <div class="row mt-3">
            <label for="weightTasksInProgress">Weighting Tasks in progress</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">%</span>
                <input type="number" class="form-control" required id="weightTasksInProgress" formControlName="weightTasksInProgress" appTwoDecimalNumber>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>