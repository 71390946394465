import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      if (it.value.customerName === undefined)
        return it.value.projectName.toLocaleLowerCase().includes(searchText);
      else
      {
        var str = (it.value.customerName + " - " + it.value.projectName).toLocaleLowerCase();
        return str.includes(searchText);
      }
    });
  }
}