import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectGroupCreate } from 'src/app/project-insights/models/project-group/project-group-create.model';

@Component({
  selector: 'app-createOrUpdate-position-modal',
  templateUrl: './project-group-create.component.html',
  styleUrls: ['./project-group-create.component.scss']
})
export class ProjectGroupCreateModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<ProjectGroupCreate>();

  public form: FormGroup = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      isActive: new FormControl('',[Validators.required] ),
      isInternal: new FormControl('',[Validators.required] ),
    },
  );

  projectGroup;
  vatDefault: string;
  tempActiveState: boolean = true;
  tempInternalState: boolean = false;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.initFormGroup();
    this.form.controls['isActive'].valueChanges.subscribe(x => this.tempActiveState = !this.tempActiveState);
    this.form.controls['isInternal'].valueChanges.subscribe(x => this.tempInternalState = !this.tempInternalState);
  }

  initFormGroup() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      isActive: [true, Validators.required],
      isInternal: [false, Validators.required],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getInvoicePosition());
      this.close();
    }
    else {
      this.toastr.error('This form has validation errors!', '');
      this.form.markAllAsTouched();
    }
  }

  getInvoicePosition() {
    let projectGroupData: ProjectGroupCreate = {
      name: this.form.controls['name'].value,
      isActive: this.form.controls['isActive'].value,
      isInternal: this.form.controls['isInternal'].value,
    };
    return projectGroupData;
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

  public close() {
    this.bsModalRef.hide();
  }

}

