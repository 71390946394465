import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent extends BaseComponent {
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  title?: string;
  body?: string;
  okayButton?: string;
  cancelButton?: string;
  icon?: string;
  color: string = 'danger';

  constructor(public bsModalRef: BsModalRef) {
    super();
  }

  confirm() {
    this.confirmed.emit(true);
    this.bsModalRef.hide();
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

}
