<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Select Project Groups</h4>
</div>
<div class="modal-body">
    <div class="invoiceContainer">
        <label for="">Select a project group</label>        
        <div class="row mt-3">
            <div class="switch-container btn-group me-2">
                <ul class="">
                    <li>
                        <div class="form-check form-switch">
                            <input class="form-check-input" [(ngModel)]="selectAll" type="checkbox" role="switch"  (change)="checkUncheckAll()">
                            <option value="m1">
                                <label for="" class="form-check-label">Select All</label>
                            </option>
                        </div>
                    </li>
                    
                    <li *ngFor="let group of issueProjectGroups" style="list-style-type: none;">
                        <div class="form-check form-switch">
                            <input class="form-check-input" [(ngModel)]="group.isSelected" type="checkbox" role="switch" (change)="isAllSelected()">
                            <option value="{{group.id}}">
                                <label for="" class="form-check-label">{{group.name}}</label>
                            </option>
                        </div>
                        
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="decline()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success" (click)="onSave()"><i class="fas fa-save"></i> Save</button>
</div>