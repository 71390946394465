import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EstProjectCosts } from '../models/est-project-costs/est-project-costs.model';
import { EstProjectCostsUpdate } from '../models/est-project-costs/est-prpject-costs-update.model';



@Injectable({
    providedIn: 'root'
})
export class EstProjectCostsService {

    constructor(private http: HttpClient) { }

    public getEstProjectCosts(): Observable<EstProjectCosts[]> {
        return this.http.get<EstProjectCosts[]>(`${environment.api}/estProjectCosts`);
    }

    public createEstProjectCosts(estProjectCosts: EstProjectCosts): Observable<string> {
        return this.http.post<string>(`${environment.api}/estProjectCosts/create`, estProjectCosts);
    }

    public getEstProjectCostsById(id: string): Observable<EstProjectCosts> {
        return this.http.get<EstProjectCosts>(`${environment.api}/estProjectCosts/${id}`);
    }

    public updateEstProjectCosts(id: string, estProjectCosts: EstProjectCostsUpdate): Observable<string> {
        return this.http.put<string>(`${environment.api}/estProjectCosts/${id}`, estProjectCosts);
    }
}