import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { LanguageSelectionMenuComponent } from './top-navbar/language-selection-menu/language-selection-menu.component';
import { SectionComponent } from './side-navbar/section/section.component';
import { LinkItemComponent } from './side-navbar/link-item/link-item.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


@NgModule({
  declarations: [
    TopNavbarComponent,
    SideNavbarComponent,
    LanguageSelectionMenuComponent,
    SectionComponent,
    LinkItemComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    TranslocoModule,
    TooltipModule,
    CollapseModule,
    RouterModule,
    BsDropdownModule
  ],
  exports: [
    TopNavbarComponent,
    SideNavbarComponent,
  ]
})
export class NavbarModule { }
