import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GridReadyEvent, IsRowSelectable, RowNode } from "ag-grid-community";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { AgGridBaseComponent } from "src/app/shared/ag-grid-shared/ag-grid-base.component";

import { AgGridEditButtonComponent } from "./ag-grid-edit-button/ag-grid-edit-button.component";

import { GridService } from "src/app/shared/services/grid.service";
import { LoadingModalComponent } from "src/app/shared/components/loading-modal/loading-modal.component";
import { ProjectGroup } from "src/app/project-insights/models/project-group/project-group.model";
import { EstProjectCosts } from "src/app/project-insights/models/est-project-costs/est-project-costs.model";
import { EstProjectCostsOverviewColumnService } from "./est-project-costs-overview-grid-column.service";
import { EstProjectCostsService } from "src/app/project-insights/services/est-project-costs.service";
import { CreateEstProjectCostsModalComponent } from "./create-est-project-costs-modal/create-est-project-costs-modal.component";

@Component({
    templateUrl: './est-project-costs-overview.component.html',
    styleUrls: ['./est-project-costs-overview.component.scss'],
    providers: [GridService, DatePipe]
})
export class EstProjectCostsOverviewComponent extends AgGridBaseComponent implements OnInit {

    @ViewChild('agGrid') agGrid: AgGridAngular;
    
    public projectGroups: ProjectGroup[];
    public estProjectCosts: EstProjectCosts[] = [];
    public loading: boolean;
    public selectedRows = [];

    downloadFilesAsZipModal?: BsModalRef | null;

    public defaultColDef: ColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true
    };

    constructor(
        private gridService: GridService,
        private estProjectCostsOverviewColumnService: EstProjectCostsOverviewColumnService,
        private estProjectCostsService: EstProjectCostsService,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super();
    }

    isRowSelectable = function (rowNode) {
        return !rowNode.data.isEditable ? true : false;
    };

    ngOnInit(): void {
        /*if (this.route.snapshot.routeConfig?.path == "invoices/create") {
            this.createInvoice();
        }*/

        this.loading = true;
        this.loadEstProjectCosts();
        this.initGrid();
    }

    private async initGrid() {
        this.addFrameWorkComponent('editButton', AgGridEditButtonComponent);
        const columnDefs = await this.estProjectCostsOverviewColumnService.getColumns();
        this.gridOptions.columnDefs = columnDefs;
        this.gridOptions.suppressRowTransform = true;
        this.gridConfigReady = true;
    }

    public override onGridReady(parameters: GridReadyEvent): void {
        this.gridApi = parameters.api;
        this.gridColumnApi = parameters.columnApi;
        super.onGridReady(parameters);
        this.fillGrid()
    }

    loadEstProjectCosts() {
        this.estProjectCostsService.getEstProjectCosts().subscribe(x => {
            this.estProjectCosts = x;
            this.loading = false;
        });
    }

    refresh(): void {
        window.location.reload();
    }

    createEstProjectCosts() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                //vatDefault: this.invoice.vatDefault,
                createOrUpdateMode: 'create'
            },
        };
        const bsModalRef = this.modalService.show(CreateEstProjectCostsModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((estProjectCosts) => {
                this.estProjectCostsService.createEstProjectCosts(estProjectCosts)
                    .subscribe(x => {

                        this.toastr.success('Project Cost Report is successfully created!'); 
                        this.router.navigateByUrl(`/est-project-costs/${x}`);
                    },
                        err => {
                            this.toastr.error('An error ocurred!')
                        }
                    )
            }
            )
    }

    private fillGrid() {
        if (!this.estProjectCosts || this.estProjectCosts == null) { return; }

        this.gridService.processGrid(this.agGrid, this.estProjectCosts, this.constructor.name);
        this.agGrid.api.sizeColumnsToFit();
    }

    onSelectionChanged() {
        this.selectedRows = this.gridApi.getSelectedRows();
    }

    //return a string format of a date used to generate a name file
    DateNameStamp() {
        var datenow = new Date();
        var day = datenow.getDate();
        var month = datenow.getMonth() + 1;
        var year = datenow.getFullYear();

        var hour = datenow.getHours();
        var mins = datenow.getMinutes();

        return day + "_" + month + "_" + year + " " + hour + ":" + mins;
    }
}
