import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { registerEscClick } from 'ngx-bootstrap/utils';
import { EstProjectCosts } from 'src/app/project-insights/models/est-project-costs/est-project-costs.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { EstProjectCostsService } from 'src/app/project-insights/services/est-project-costs.service';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';


@Component({
  selector: 'app-create-est-project-costs-modal',
  templateUrl: './create-est-project-costs-modal.component.html',
  styleUrls: ['./create-est-project-costs-modal.component.scss']
})
export class CreateEstProjectCostsModalComponent extends BaseComponent implements OnInit {
  
  @Output() confirmed = new EventEmitter<EstProjectCosts>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;

  //Modal variables

  title?: string;
  body?: string;
  okayButton?: string;
  cancelButton?: string;

  showMessageBox = false;
  message = new FormControl('', Validators.required);
  submitted = false;

  //Formular variable

  public selectedProjectGroupId: string;
  public projectGroups: ProjectGroup[];
  

  public estProjectCosts: EstProjectCosts;
  public createDate: Date;
  public hourlyRateProject: number;
  public weightTasksInProgress: number;


  public loading: boolean;

  
  constructor(
    public bsModalRef: BsModalRef,
    private estProjectCostsService: EstProjectCostsService,
    private projectGroupService: ProjectGroupService,
  ) {
    super();
  }

  ngOnInit(): void {
    //this.loading = true;
    this.loadProjectGroups();
  }

  //Modal functions

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  //Formular Functions

  createEstProjectCosts() {
    this.estProjectCosts = new EstProjectCosts();

    this.estProjectCosts.projectGroupId = this.selectedProjectGroupId;
    this.estProjectCosts.hourlyRateProject = this.hourlyRateProject;
    this.estProjectCosts.weightTasksInProgress = this.weightTasksInProgress;
    this.confirmed.emit(this.estProjectCosts);
    
    this.bsModalRef.hide();
  }

  
  loadProjectGroups() {
    this.projectGroupService.getProjectGroups().subscribe(x => {
      this.projectGroups = x;
      this.loading = false;
    });
  }

  selectedProjectGroupChanged(id: string) {
    this.selectedProjectGroupId = id;
  }

    fillZero(number: Number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

  close(){
    this.bsModalRef.hide();
  }

}
