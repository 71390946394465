import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridInvoiceEditButtonComponent } from './ag-grid-edit-button/ag-grid-invoice-edit-button.component';
import { TranslocoService } from '@ngneat/transloco';
import { AgGridInfoButtonComponent } from './ag-grid-info-button/ag-grid-info-button.component';

@Injectable()
export class InvoiceOverviewColumnService {
  constructor(private datePipe: DatePipe, 
    private translateService: TranslocoService
  ) { }

  async getColumns(): Promise<(ColDef | ColGroupDef)[]> {
    const cols: ColDef[] = [
      {
        field: 'IsEditable',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: 'Download',
        cellEditorPopupPosition: 'under',
        filter: false,
        minWidth: 50 ,
        maxWidth: 50
      },
      {
        field: 'invoiceNr',
        headerName: 'Invoice No.',
        sort: 'desc',
        width: 200
      },
      {
        field: 'billingGroup.name',
        headerName: 'Billing group',
        minWidth: 250
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        filter: 'agDateColumnFilter',
        filterParams: filterDateParams,
        valueFormatter: (data) => { return this.datePipe.transform(data.value, "dd.MM.yyyy") },
        width: 200
      },
      {
        field: 'startDate',
        headerName: 'Start Date',
        filter: 'agDateColumnFilter',
        filterParams: filterDateParams,
        valueFormatter: (data) => { return this.datePipe.transform(data.value, "dd.MM.yyyy") },
        width: 200
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        filter: 'agDateColumnFilter',
        filterParams: filterDateParams,
        valueFormatter: (data) => { return this.datePipe.transform(data.value, "dd.MM.yyyy") },
        width: 200
      },
      {
        field: 'totalPriceWithVat',
        headerName: 'Total with VAT',
        valueFormatter: params => { return params.value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'") + " CHF" },
        width: 200,
      },
      {
        field: 'statusId',
        minWidth: 100,
        maxWidth: 100,
        headerName: 'Status',
        cellClass: params => {
          switch (params.data.statusId) {
            case 1: return 'text-danger'
            case 2: return 'text-warning'
            case 3: return 'text-success'
            default: return 'text-dark'
          }
        },
        valueGetter: params => {
          return this.translateService.translate('invoiceEnumStatus.' + params.data.statusId)
        },
      },
      {
        field: 'info',
        headerName: '',
        minWidth: 70 ,
        maxWidth: 70,
        cellRenderer: AgGridInfoButtonComponent,
        filter: false,
      },
      {
        field: '',
        cellRenderer: AgGridInvoiceEditButtonComponent,
        cellEditorPopupPosition: 'under',
        minWidth: 70 ,
        maxWidth: 70,
        filter: false,
        pinned: 'right'
      },
    ];
    return cols;
  }

}

var filterDateParams = {
  comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {

    var cellDatePreview = new Date(cellValue)

    var dd = cellDatePreview.getDate()
    var mm = cellDatePreview.getMonth()
    var yyyy = cellDatePreview.getFullYear()

    var cellDate = new Date(
      Number(yyyy),
      Number(mm),
      Number(dd)
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    
  },
  browserDatePicker: true
};