<li class="mb-2 cursor-pointer">
    <a 
      [routerLink]="routerLinkInput"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact:true}"
      class="nav-link text-white" 
      [tooltip]="collapsedSidebar ? (translocoText | titlecase) : ''" 
      placement="bottom">
        <i class="{{iconCode}}"> </i> 
        <span [ngStyle]="{'margin-left': collapsedSidebar ? '0rem' : '0.6rem'}" *ngIf="!collapsedSidebar">{{ translocoText | titlecase }}</span>
    </a>
  </li>