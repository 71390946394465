import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  InteractionStatus,
  PublicClientApplication
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BaseComponent } from 'src/lib/base.component';
import { AccountInfoModel } from './security/models/account-info.model';
import { AuthService } from './security/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent extends BaseComponent implements OnInit {

  collapsed:boolean = false;
  isIframe = false;

  userInformation: AccountInfoModel;

  private msalInstance: PublicClientApplication;
  private readonly _destroying$ = new Subject<void>();

  isIEOrEdgeLegacy: boolean;

  constructor(
    private router: Router,
    private msalAuthService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    if (this.isIEOrEdgeLegacy) {
      this.router.navigate(['unsupported-browser']);
    }
    this.isIframe = window !== window.parent && !window.opener;
    this.msalInstance = new PublicClientApplication(
      this.msalAuthService.instance.getConfiguration()
    );
    console.log(environment);
    this.updateToken();
  }

  toggleCollapse(collapsed: boolean) {
    this.collapsed = collapsed;
  }

  private updateToken() {
    this.broadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        if (this.msalInstance.getAllAccounts().length > 0) {
          const tokenRequest = this.getToken();
          this.msalInstance.acquireTokenSilent(tokenRequest)
            .then((request) => {
              this.authService.loginUserToBackend(request.idToken) // Intern Login
                .pipe(switchMap(() =>
                  this.authService.getMyAccount())) // Get Account Information
                .subscribe(
                  res => { // Successful Login -> Store Billing JWT
                    this.userInformation = res;
                  },
                  error => { // Error on Login -> Remove Token, because we don't want the user to use the Billing JWT, which could be still valid.
                    localStorage.removeItem('app_token');
                    this.router.navigateByUrl('/unauthorized');
                  }
                )
              })
              .catch((error) => {
                console.log(error);
                if (localStorage.getItem('refreshed') === null) {
                  localStorage['refreshed'] = true;
                  window.location.reload();
                } 
                else {
                  localStorage.removeItem('refreshed');
                  this.msalInstance.loginRedirect();
                }
            });
        }
      });
  }

  private getToken(): any {
    const accounts = this.msalInstance.getAllAccounts();
    const tokenRequest = {
      account: accounts[0],
      scopes: ['openid'],
    };
    return tokenRequest;
  }

  override ngOnDestroy(): void {
    localStorage.clear();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
