import { ProjectCostReportStats } from "./project-cost-report-stats.model";

export class EstProjectCosts {
    id: string;
    projectGroupName: string;
    projectGroupId: string;
    createDate: Date;
    totalEstimateProjectCosts: number;
    projectCosts: number;
    mwSt: number;
    hourlyRateProject: number;
    weightTasksInProgress: number;
    projectCostReportStats: ProjectCostReportStats;
}