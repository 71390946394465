<div class="input-group flex-nowrap">
    <input
      type="text"
      bsDaterangepicker
      #appDaterangepicker
      class="form-control border border-end-0"
      [id]="id"
      [ngClass]="{ 'is-invalid border-danger': isInvalidValue }"
      placeholder="DD.MM.YYYY - DD.MM.YYYY"
      [formControl]="valueFormControl"
      [isDisabled]="isDisabled"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [datesEnabled]="datesEnabled"
      [datesDisabled]="datesDisabled"
      [bsConfig]="{
        containerClass: 'theme-dark-blue',
        rangeInputFormat : 'DD.MM.YYYY',
        dateInputFormat: 'DD.MM.YYYY'}"/>
    <button
      type="button"
      class="btn btn-hover border border-start-0"
      [ngClass]="{ 'is-invalid border-danger': isInvalidValue }"
      [tooltip]="toolTipValue"
      (click)="appDaterangepicker.focus(); appDaterangepicker.click()">
      <i class="text-muted {{ iconCode }}"></i>
    </button>
 </div>