import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { GitlabProject } from 'src/app/project-insights/models/gitlab-project/gitlab-project.model';
import { ProjectGroupGitlabProjects } from 'src/app/project-insights/models/project-group/project-group-gitlab-projects.model';
import { ProjectGroup } from 'src/app/project-insights/models/project-group/project-group.model';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';
import { UpdateGitlabProjectsModalComponent } from './update-gitlab-projects-modal/update-gitlab-projects-modal.component';

@Component({
  selector: 'app-gitlab-projects',
  templateUrl: './gitlab-projects.component.html',
  styleUrls: ['./gitlab-projects.component.scss']
})
export class GitLabProjectsComponent implements OnInit {

  @Input() projectGroup: ProjectGroup;

  public projects: GitlabProject[];

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private projectGroupService: ProjectGroupService
    ) { }

  ngOnInit(): void {
    this.loadProjects();
  }

  loadProjects() {
    this.projectGroupService.getProjectGroupGitlabProjects(this.projectGroup.id).subscribe((x: any) => {
        this.projects = x;
    });
}

onEditGitlabProjects() {
  const initialState: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    initialState: {
      projectGroup: this.projectGroup
    },
  };
  const bsModalRef = this.modalService.show(UpdateGitlabProjectsModalComponent, initialState);

  bsModalRef.content?.confirmed.pipe(take(1))
    .subscribe((updatedGitlabProjects: ProjectGroupGitlabProjects) => {
      this.projectGroupService.updateProjectGroupGitlabProjects(this.projectGroup.id, updatedGitlabProjects).subscribe(
        x => {
          this.loadProjects();
          this.toastr.success("Data successfully updated!")
        },
        err => {
          this.toastr.error(err.error, "An error occured!")
        })
    })
}

}
