<div class="card mt-2">
    <h4 class="card-header d-flex justify-content-between align-items-center">
        <div><i class="fa-solid fa-stopwatch fa-sm"></i> {{'Timetracker Projects'}}</div>
        <button type="button" class="btn btn-sm btn-primary" (click)="onEditTimetrackerProjects()">
            <i class="fas fa-edit"></i>
        </button>
    </h4>
    <div class="card-body">
        <div class="row">
            <div class="col-sm">
                <table class="table">
                    <tbody>
                        <tr *ngFor="let project of projects">
                            <td>{{project.customerName}} - {{project.name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>