<div (ngSubmit)="onSubmit()">
    <div class="card mb-2">
        <div class="card-header border-bottom-0">
            <div class="row">
                <!-- Daterange -->
                <div class="col-4 border-end">
                    <div class="row">
                        <div class="col-lg-12">
                            <label for="end" class="form-label">Date range</label>
                            <div class="mb-1">
                                <app-daterangepicker [formControl]="daterange" (ngModelChange)="onSubmit()"></app-daterangepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col buttons-h-list">
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setThisWeekDates()">This week</button>
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setCurrentMonthDates()">This month</button>
                            <button class="btn btn-light border me-2 mb-1 btn-sm" (click)="setLastMonthDates()">Last month</button>
                        </div>
                    </div>
                </div>
                <!-- Employee Dropdown -->
                <div class="col-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <label for="employeeSelect" class="form-label">Employee</label>
                            <select id="employeeSelect" class="form-select" [formControl]="selectedEmployeeId">
                                <option value="" disabled>Select an employee</option>
                                <option *ngFor="let employee of employees" [value]="employee.id">
                                    {{ employee.firstName }} {{ employee.lastName }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col d-flex justify-content-start">
                            <!-- Reset Search Button -->
                            <button type="button" class="btn btn-sm btn-secondary" (click)="resetSearchState()">
                                <i class="fas fa-clock-rotate-left"></i> Reset Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
