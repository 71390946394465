import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectGroup } from "../models/project-group/project-group.model";
import { ProjectGroupCreate } from "../models/project-group/project-group-create.model";
import { ProjectGroupNameStatus } from "../models/project-group/project-group-name-status.model";
import { ProjectGroupGitlabProjects } from "../models/project-group/project-group-gitlab-projects.model";
import { ProjectGroupTimetrackerProjects } from "../models/project-group/project-group-timetracker-projects.model";
import { TimetrackerProject } from "../models/timetracker-project/timetracker-project.model";
import { GitlabProject } from "../models/gitlab-project/gitlab-project.model";
import { PlanningProjectGroup } from "../models/planning/planning-project-group.model";

@Injectable({
    providedIn: 'root'
})
export class ProjectGroupService {

    constructor(private http: HttpClient) { }

    public getProjectGroups(): Observable<ProjectGroup[]> {
        return this.http.get<ProjectGroup[]>(`${environment.api}/projectGroup`);
    }

    public getPlanningProjectGroups(): Observable<PlanningProjectGroup[]> {
        return this.http.get<PlanningProjectGroup[]>(`${environment.api}/projectGroup/planning`);
    }

    public getActiveProjectGroups(): Observable<ProjectGroup[]> {
        return this.http.get<ProjectGroup[]>(`${environment.api}/projectGroup/active`);
    }

    public createProjectGroup(projectGroupCreateModel: ProjectGroupCreate): Observable<ProjectGroup> {
        return this.http.post<ProjectGroup>(`${environment.api}/projectGroup/create`, projectGroupCreateModel);
    }

    public getProjectGroupById(id: string): Observable<ProjectGroup> {
        return this.http.get<ProjectGroup>(`${environment.api}/projectGroup/${id}`);
    }

    public updateProjectGroupNameAndStatus(id: string, projectGroupNameAndStatus: ProjectGroupNameStatus): Observable<ProjectGroupNameStatus> {
        return this.http.put<ProjectGroupNameStatus>(`${environment.api}/projectGroup/${id}/nameAndStatus`, projectGroupNameAndStatus);
    }

    public deleteProjectGroup(id: string): Observable<ProjectGroup> {
        return this.http.delete<ProjectGroup>(`${environment.api}/projectGroup/${id}`);
    }

    public updateProjectGroupGitlabProjects(id: string, projectGroupGitlabProjects: ProjectGroupGitlabProjects): Observable<ProjectGroupGitlabProjects> {
        return this.http.put<ProjectGroupGitlabProjects>(`${environment.api}/projectGroup/${id}/gitlabProjects`, projectGroupGitlabProjects);
    }

    public updateProjectGroupTimetrackerProjects(id: string, projectGroupTimetrackerProjects: ProjectGroupTimetrackerProjects): Observable<ProjectGroupTimetrackerProjects> {
        return this.http.put<ProjectGroupTimetrackerProjects>(`${environment.api}/projectGroup/${id}/timeTrackerProjects`, projectGroupTimetrackerProjects);
    }

    public getTimetrackerProjects(): Observable<TimetrackerProject[]> {
        return this.http.get<TimetrackerProject[]>(`${environment.api}/projectGroup/timeTrackerProjects`);
    }

    public getGitlabProjects(): Observable<GitlabProject[]> {
        return this.http.get<GitlabProject[]>(`${environment.api}/projectGroup/gitlabProjects`);
    }

    public getProjectGroupTimetrackerProjects(id: string): Observable<TimetrackerProject[]> {
        return this.http.get<TimetrackerProject[]>(`${environment.api}/projectGroup/${id}/timeTrackerProjects`);
    }

    public getProjectGroupGitlabProjects(id: string): Observable<GitlabProject[]> {
        return this.http.get<GitlabProject[]>(`${environment.api}/projectGroup/${id}/gitlabProjects`);
    }
}