<div (ngSubmit)="onSubmit()">
    <div class="card mb-2">
        <div class="card-header border-bottom-0">
            <div class="row">
                <!-- Daterange -->
                <div class="col-6">
                    <div class="row">
                        <div class="col-lg-9">
                            <label for="end" class="form-label">Date range</label>
                            <div class="mb-1">
                                <app-daterangepicker [formControl]="daterange" (ngModelChange)="onSubmit()"></app-daterangepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col buttons-h-list">
                            <button class="btn btn-light border me-2 btn-sm" (click)="setLastMonthDates()">Last month</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentMonthDates()">Current month</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setLastQuarterDates()">Q{{this.lastQuarter + 1}} {{this.lastQuarterYear}}</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentQuarterDates()">Q{{this.currentQuarter + 1}} {{this.currentQuarterYear}}</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setNextQuarterDates()">Q{{this.nextQuarter + 1}} {{this.nextQuarterYear}}</button>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col buttons-h-list">
                            <button class="btn btn-light border me-2 btn-sm" (click)="setCurrentWeekDates()">Current week</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setLastWeekDates()">Last week</button>
                            <button class="btn btn-light border me-2 btn-sm" (click)="setWeekBeforeLastDates()">Week before last</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col d-flex justify-content-start">
                    <!-- Reset Search Button -->
                    <button type="button" class="btn btn-sm btn-secondary" (click)="resetSearchState()">
                        <i class="fas fa-clock-rotate-left"></i> Reset Search
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>