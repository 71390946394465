import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridEditButtonComponent } from './ag-grid-edit-button/ag-grid-edit-button.component';
import { AgGridUpdateButtonComponent } from './ag-grid-update-button/ag-grid-update-button.component';

@Injectable()
export class EstProjectCostsOverviewColumnService {
  constructor(private datePipe: DatePipe) { }

  async getColumns(): Promise<(ColDef | ColGroupDef)[]> {
    const cols: ColDef[] = [
      {
        field: 'projectGroupName',
        headerName: 'Project groups',
        minWidth: 250
      },
      {
        field: 'createDate',
        headerName: 'Create date',
        filter: 'agDateColumnFilter',
        filterParams: filterDateParams,
        valueFormatter: (data) => { return this.datePipe.transform(data.value, "dd.MM.yyyy") },
        width: 200,
        sort: 'desc',
      },
      {
        field: 'totalEstimateProjectCosts',
        headerName: 'Total Estimate project costs',
        valueFormatter: params => { return params.value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'") + " CHF" },
        width: 200,
      },
      {
        field: '',
        cellRenderer: AgGridEditButtonComponent,
        cellEditorPopupPosition: 'under',
        minWidth: 70,
        maxWidth: 70,
        filter: false,
        pinned: 'right'
      },
      {
        field: '',
        cellRenderer: AgGridUpdateButtonComponent,
        cellEditorPopupPosition: 'under',
        minWidth: 50,
        maxWidth: 50,
        filter: false,
        pinned: 'right',
        cellStyle: { 'padding': '0px' }  
      },
    ];
    return cols;
  }

}

var filterDateParams = {
    comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
  
      var cellDatePreview = new Date(cellValue)
  
      var dd = cellDatePreview.getDate()
      var mm = cellDatePreview.getMonth()
      var yyyy = cellDatePreview.getFullYear()
  
      var cellDate = new Date(
        Number(yyyy),
        Number(mm),
        Number(dd)
      );
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return -2;
    },
    browserDatePicker: true
  };