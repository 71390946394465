import {
    ColumnApi,
    GridApi,
    GridOptions,
    GridReadyEvent
  } from 'ag-grid-community';
  import { BaseComponent } from 'src/lib/base.component';
  import { getDefaultGridOptions } from './ag-grid-default-options';
  
  export abstract class AgGridBaseComponent extends BaseComponent {
    gridOptions: GridOptions = getDefaultGridOptions();
    protected gridApi?: GridApi;
    protected gridColumnApi?: ColumnApi;
  
    gridConfigReady = false;
  
    frameworkComponents: any = {};
  
    constructor() {
      super();
    }
  
    addFrameWorkComponent(key: string, renderer: any) {
      this.frameworkComponents[key] = renderer;
      this.gridOptions.components = this.frameworkComponents;
    }
  
    public onGridReady(parameters: GridReadyEvent): void {
      this.gridApi = parameters.api;
      this.gridColumnApi = parameters.columnApi;
    }
  
    onCopy(e: any) {
      e.clipboardData.setData('text/plain', e.srcElement.innerText);
      e.preventDefault();
    }
  }
  