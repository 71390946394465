import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectGroup } from "../models/project-group/project-group.model";
import { ProjectGroupCreate } from "../models/project-group/project-group-create.model";
import { ProjectGroupNameStatus } from "../models/project-group/project-group-name-status.model";
import { ProjectGroupGitlabProjects } from "../models/project-group/project-group-gitlab-projects.model";
import { ProjectGroupTimetrackerProjects } from "../models/project-group/project-group-timetracker-projects.model";
import { TimetrackerProject } from "../models/timetracker-project/timetracker-project.model";
import { GitlabProject } from "../models/gitlab-project/gitlab-project.model";
import { ProjectStatus } from "../models/project-status/project-status.model";

@Injectable({
    providedIn: 'root'
})
export class ProjectStatusService {

    constructor(private http: HttpClient) { }

    /*public getProjectGroups(): Observable<ProjectGroup[]> {
        return this.http.get<ProjectGroup[]>(`${environment.api}/projectGroup`);
    }*/

    public getProjectStatusByProjectGroupId(id: string): Observable<ProjectStatus> {
        return this.http.get<ProjectStatus>(`${environment.api}/projectStatus/${id}`);
    }

}