<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Timetracker Projects</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" id="form" autocomplete="off" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <input type="email" class="form-control" id="search-text" aria-describedby="search-text" 
            [(ngModel)]="searchText" placeholder="Project..." [ngModelOptions]="{standalone: true}"
            autofocus>
        </div>
        <div class="row mb-2">
            <div class="form-group col">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="th-isActive">Active</th>
                            <th>Project</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="timetrackerProjectsArray">
                        <tr *ngFor="let timetrackerProject of timetrackerProjectsArray.controls | appFilter: searchText; let index = index" [formGroupName]="index">
                            <td class="noborder">
                                <div class="form-check form-switch" id="div-isActive">
                                    <input id="isActiveModal" class="form-check-input" type="checkbox" role="switch" formControlName="isActive" [(ngModel)]="timetrackerProject.value.isActive">
                                </div>
                            </td>
                            <td class="noborder">
                                <p class="projectName">{{timetrackerProject.value.customerName}} - {{timetrackerProject.value.projectName}}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>