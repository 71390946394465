import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PmtUserRoleService } from 'src/app/security/services/pmt-user-role.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {

  @Output() isCollapsedSidebar = new EventEmitter<boolean>();

  collapsedSidebar: boolean = false;
  hasManagementRole: boolean = false;

  constructor(
    private pmtUserRoleService: PmtUserRoleService
  ) { }

  ngOnInit(): void {
    this.pmtUserRoleService.hasManagementRole().subscribe(hasRole => {
      this.hasManagementRole = hasRole;
    });
  }

  toggleNavbar() {
    this.collapsedSidebar = !this.collapsedSidebar;
    this.isCollapsedSidebar.emit(!this.collapsedSidebar);
  }

}
