import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { IssueSearchData } from 'src/app/project-insights/models/issue/issue-search.model';
import { IssueProjectGroupsModalComponent } from '../../issue-project-groups-modal/issue-project-groups-modal.component';
import { IssueProjectGroup } from 'src/app/project-insights/models/issue/issue-project-groups-search.model';

@Component({
  selector: 'app-issue-searchbar',
  templateUrl: './issue-searchbar.component.html',
  styleUrls: ['./issue-searchbar.component.scss']
})
export class IssueSearchbarComponent implements OnInit {

  @Output() issueSearchData = new EventEmitter<IssueSearchData>();

  textfieldControl: FormControl = new FormControl();
  daterange: FormControl = new FormControl();
  selectedProjectGroups: IssueProjectGroup[] = [];
  loadAllIssuesControl: FormControl = new FormControl(false);
  searchDescriptionControl: FormControl = new FormControl(false);
  dateRange: Date[] = [];

  private textfieldControlSub: any; 
  private loadAllIssuesControlSub: any;
  private searchDescriptionControlSub: any;
  private daterangeControlSub: any; 

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.initSearchForm();
    this.subscribeToControlChanges(); 
  }

  subscribeToControlChanges(): void {
    this.textfieldControlSub = this.textfieldControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => this.onSubmit());

    this.loadAllIssuesControlSub = this.loadAllIssuesControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => this.onSubmit());

    this.searchDescriptionControlSub = this.searchDescriptionControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => this.onSubmit());

      this.daterangeControlSub = this.daterange.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => this.onSubmit());
  }

  unsubscribeFromControlChanges(): void {
    if (this.textfieldControlSub) {
      this.textfieldControlSub.unsubscribe();
    }
    if (this.loadAllIssuesControlSub) {
      this.loadAllIssuesControlSub.unsubscribe();
    }
    if (this.searchDescriptionControlSub) {
      this.searchDescriptionControlSub.unsubscribe();
    }
    if (this.daterange) {
      this.daterangeControlSub.unsubscribe();
    }
  }

  initSearchForm() {
    let searchData: IssueSearchData;
    this.textfieldControl.patchValue(searchData?.textfield ?? '');
    this.daterange.patchValue(this.dateRange);
    this.loadAllIssuesControl.patchValue(false);
    this.searchDescriptionControl.patchValue(false);
  }

  resetSearchState() {
    this.unsubscribeFromControlChanges();

    this.textfieldControl.reset();
    this.selectedProjectGroups = [];
    this.loadAllIssuesControl.reset(false);
    this.searchDescriptionControl.reset(false);
  
    this.daterange.reset();

    this.subscribeToControlChanges();
  
    this.onSubmit();
  }
  
  onSubmit() {
    const issueSearchData = this.getFormData();
    this.issueSearchData.emit(issueSearchData);
  }

  getFormData(): IssueSearchData {
    var issueSearchData: IssueSearchData = {
      textfield: this.textfieldControl.value,
      projectGroups: this.selectedProjectGroups?.map(x => x.id),
      startDate: this.daterange.value ? this.daterange.value[0] : null,
      endDate: this.daterange.value ? this.daterange.value[1] : null,
      loadAllIssues: this.loadAllIssuesControl.value,
      searchDescription: this.searchDescriptionControl.value
    };
    return issueSearchData;
  }

  selectProjectGroups() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        selectedProjectGroups: this.selectedProjectGroups
      },
    };
    
    this.modalService.show(IssueProjectGroupsModalComponent, initialState)
      .content?.confirmed.subscribe((selectedProjectGroups: IssueProjectGroup[]) => {
        this.selectedProjectGroups = selectedProjectGroups;
        this.onSubmit();
    });
  }

  setLastMonthDates() {
    const dateNow = new Date();
    this.dateRange = [
      new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1, 0, 0, 0, 0),
      new Date(dateNow.getFullYear(), dateNow.getMonth(), 0, 23, 59, 59, 999)
    ];
    this.daterange.patchValue(this.dateRange);
  }

  setCurrentMonthDates() {
    const dateNow = new Date();
    this.dateRange = [
      new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0, 0),
      new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0, 23, 59, 59, 999)
    ];
    this.daterange.patchValue(this.dateRange);
  }

  setThisWeekDates() {
    const dateNow = new Date();
    const currentDay = dateNow.getDay();
    
    let startDate = new Date(dateNow);
    startDate.setDate(dateNow.getDate() + 1 - currentDay);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(dateNow);
    endDate.setDate(dateNow.getDate() + 7 - currentDay);
    endDate.setHours(23, 59, 59, 999);

    this.dateRange = [startDate, endDate];
    this.daterange.patchValue(this.dateRange);
  }
}