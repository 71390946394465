<div class="container-fluid mt-3">
    <div class="d-flex pb-2 ">
        <h2 class="pe-3">Issues</h2>
    </div>

    <app-issue-searchbar (issueSearchData)="loadIssuesBySearch($event)"></app-issue-searchbar>

    <ng-container *ngIf="onInit">
        <div class="container-fluid" id="noSearch" >
            <p>No search result. Please search for an Project GitLab and/or an Issue #.</p>
        </div>
    </ng-container>

    <ng-container *ngIf="loading">
        <div class="loading-container">
            <div class="loading-spinner">
                <div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="(!loading && !onInit)">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="th-project-name">Project #</th>
                                <th class="th-text">Issue</th>
                                <th class="th-title">Issue Title</th>
                                <th class="th-stats">Billable total</th>
                                <th class="th-stats">Non billable total</th>
                                <th class="th-stats">Grand total</th>
                                <th class="th-estimate">Estimate total</th>
                                <th class="th-estimate">Delta</th> 
                                <th class="th-milestone">Milestones</th>
                                <th class="th-status">Status</th>

                                <th class="th-icon"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let issue of issues">
                                <td class="td-project-name">{{issue.projectName}}</td>
                                <td class="text-left px-1 border-0 font-weight-bold">#{{issue.number}}</td>
                                <a class="td-name" href={{issue.gitLabLink}} target="_blank">{{issue.title}}</a>
                                <td class="td-stats">{{issue.grandTotalHours[0]}}</td>
                                <td class="td-stats">{{issue.grandTotalHours[1]}}</td>
                                <td class="td-stats">{{issue.grandTotalHours[2]}}
                                </td>
                                <td *ngIf="!issue.isTotalMoreThanEstimated" class="td-estimate">{{issue.estimateTotalHours}}</td>
                                <td *ngIf="issue.isTotalMoreThanEstimated" class="td-estimate-red">{{issue.estimateTotalHours}}</td>
                                
                                <td *ngIf="!issue.deltaNonBillable && !issue.deltaBillable" class="td-delta">{{issue.deltaHours}}</td>
                                <td *ngIf="issue.deltaNonBillable" class="td-delta-orange">{{issue.deltaHours}}</td>
                                <td *ngIf="issue.deltaBillable" class="td-delta-red">{{issue.deltaHours}}</td>

                                <td class="td-milestone" *ngIf="issue.status == 'Rejected'"></td>
                                <td class="td-milestone" *ngIf="issue.status !== 'Rejected'">{{issue.milestoneString}}</td>
                                <td *ngIf="issue.status == 'Open'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="Open" placement="top" container="body">
                                        <i class="fa-regular fa-rectangle-list"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'ToDo'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="ToDo" placement="top" container="body">
                                        <i class="fa-solid fa-list-check"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'Doing'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="Doing" placement="top" container="body">
                                        <i class="fa-solid fa-spinner"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'Re-Open ToDo'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="Re-Open ToDo" placement="top" container="body">
                                        <i class="fa-solid fa-rotate-right"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'Test Ready'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="Test Ready" placement="top" container="body">
                                        <i class="fa-solid fa-flask-vial"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'Closed'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="Done" placement="top" container="body">
                                        <i class="fa-solid fa-check"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'Rejected'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="Rejected" placement="top" container="body">
                                        <i class="fa-solid fa-trash"></i>
                                    </button>
                                </td>
                                <td *ngIf="issue.status == 'Active'" class="td-status"> 
                                    <button type="button" class="btn circle-info" tooltip="No Labels yet" placement="top" container="body">
                                        <i></i>
                                    </button>
                                </td>

                                <td *ngIf="!issue.isTotalMoreThanEstimated" class="td-icons">
                                    <button type="button" class="btn circle-info" [tooltip]="template" placement="top" container="body">
                                        <i class="fa fa-circle-info" ></i>
                                    </button>
                                
                                    <ng-template #template>
                                        <p style="text-align: left;">Total Dev.: {{issue.developmentHours}}</p>
                                        <p style="text-align: left;">Total Project Mgmt.: {{issue.projectManagementHours}}</p>
                                        <p style="text-align: left;">Total Testing: {{issue.testingHours}}</p>
                                        <p style="text-align: left;">Total Other: {{issue.otherHours}}</p>
                                    </ng-template>
                                </td>
                                <td *ngIf="issue.isTotalMoreThanEstimated" class="td-icons"> 
                                    <button type="button" class="btn circle-info" [tooltip]="template" placement="top" container="body">
                                        <i style="color: red;" class="fa fa-circle-info" ></i>
                                    </button>
                                
                                    <ng-template #template>
                                        <p style="text-align: left;">Total Dev.: {{issue.developmentHours}}</p>
                                        <p style="text-align: left;">Total Project Mgmt.: {{issue.projectManagementHours}}</p>
                                        <p style="text-align: left;">Total Testing: {{issue.testingHours}}</p>
                                        <p style="text-align: left;">Total Other: {{issue.otherHours}}</p>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="border-1 bg-light">
                            <tr>
                                <th class="td-project-name"></th>
                                <th class="text-left px-1 border-0 font-weight-bold"></th>
                                <th class="td-name"></th>
                                <th class="td-stats">{{issueOverview.issueCalculatedInfoModel.billableTotal}}</th>
                                <th class="td-stats">{{issueOverview.issueCalculatedInfoModel.nonBillableTotal}}</th>
                                <th class="td-stats">{{issueOverview.issueCalculatedInfoModel.grandTotal}}</th>
                                <th class="td-estimate">{{issueOverview.issueCalculatedInfoModel.estimateTotal}}</th>
                                <th class="td-delta">{{issueOverview.issueCalculatedInfoModel.deltaTotal}}</th>
                                <th class="td-status"></th>
                                <th class="td-icons"></th>
                                <th class="td-icons"></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
</ng-container>

